import React from 'react';
import classnames from 'classnames';
import { useMobile } from '../../utils/hooks';
import { RHImage } from '../../theme/wrappers/RHImage';

export default function HDCarouselItem({ image }) {
  const { isMobile } = useMobile();

  return (
    <div className={classnames({ 'pe-4': !isMobile })}>
      <RHImage image={image} height={'325px'} radius={'5px'} alt={''} />
    </div>
  );
}
