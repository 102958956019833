export const PaymentConstants = {
  PAY_CASH: 'PAY_CASH',
  // PAY_MEDICAID: 'PAY_MEDICAID',
  // PAY_MEDICARE: 'PAY_MEDICARE',
  PAY_VOUCHER: 'PAY_VOUCHER',
  PAY_MILITARY: 'PAY_MILITARY',
  PAY_MONEY: 'PAY_MONEY',
  PAY_INSURANCE: 'PAY_INSURANCE',
  PAY_CHECK: 'PAY_CHECK',
  PAY_CREDIT: 'PAY_CREDIT',
};

export const PaymentMethodsData = [
  {
    id: PaymentConstants.PAY_CASH,
    label: 'Cash',
    service_id: 195,
  },
  // commented out for alpha release
  // {
  //   'id': PaymentConstants.PAY_MEDICAID,
  //   'label': 'Medicaid',
  //   'service_id': 196,
  // },
  // {
  //   'id': PaymentConstants.PAY_MEDICARE,
  //   'label': 'Medicare',
  //   'service_id': 197,
  // },
  {
    id: PaymentConstants.PAY_VOUCHER,
    label: 'Voucher',
    service_id: 202,
  },
  {
    id: PaymentConstants.PAY_MILITARY,
    label: 'Military',
    service_id: 211,
  },
  {
    id: PaymentConstants.PAY_MONEY,
    label: 'Money Order',
    service_id: 525,
  },
  {
    id: PaymentConstants.PAY_INSURANCE,
    label: 'Accepts Private Insurance',
    service_id: 635,
  },
  {
    id: PaymentConstants.PAY_CHECK,
    label: 'Personal Check',
    service_id: 727,
  },
  {
    id: PaymentConstants.PAY_CREDIT,
    label: 'Credit',
    service_id: 728,
  },
];
