import React from 'react';
import { Text } from '../../theme';
import { CriminalBackgroundData } from '../../AppData/GetHelpConstants/CriminalBackground';
import { ResidentRequirementsData } from '../../AppData/GetHelpConstants/ResidentRequirements';
import CheckedList from './CheckedList';
import { useMobile } from '../../utils/hooks';

export default function ResidentRequirements({ houseInfo }) {
  const { isMobile } = useMobile();

  const createNotAcceptedConvictionsList = () => {
    let stringOfConvictions = '';
    CriminalBackgroundData.map((legalException) => {
      if (houseInfo.residentRequirements.correctionsBackground.includes(legalException.id)) {
        stringOfConvictions += `${legalException.label}, `;
      }
    });
    stringOfConvictions =
      stringOfConvictions.charAt(0).toUpperCase() + stringOfConvictions.slice(1).slice(0, -2);

    return <span>{stringOfConvictions}</span>;
  };

  return (
    <>
      <div className="row mt-3">
        <Text XX_large bold className="mb-4">
          Resident Requirements
        </Text>
        <div className="col mb-3">
          <CheckedList
            returnedValues={houseInfo.residentRequirements.residentRequirementsList}
            comparisonList={ResidentRequirementsData}
            type={isMobile ? 'single' : 'inline'}
          />
        </div>
        <div className="row">
          {houseInfo.residentRequirements.minAbstinence && (
            <div className="col-12 col-sm-12 col-md-4">
              <Text large bold className="mb-2">
                Minimum Abstinence Requirement
              </Text>
              <div className="mb-3">
                <Text large>{houseInfo.residentRequirements.minAbstinence}</Text>
              </div>
            </div>
          )}
          {houseInfo.residentRequirements.correctionsBackground.length <
            CriminalBackgroundData.length && (
            <div className="col-12 col-sm-12 col-md-4">
              <Text large bold className="mb-2">
                Correctional History
              </Text>
              <div className="mb-3">
                <Text regular>
                  We do not accept individuals convicted of the following or associated with the
                  following legal circumstances:
                </Text>
                <Text bold regular>
                  {createNotAcceptedConvictionsList()}
                </Text>
                <Text italic>Contact the house for more information about legal exceptions.</Text>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
