import React from 'react';
import { Text } from '../../theme';
import CheckedList from './CheckedList';
import { ServicesData } from '../../AppData/GetHelpConstants/Services';
import { AdditionalWalkableServicesData } from '../../AppData/GetHelpConstants/AdditionalWalkableServices';
import MoreInfoModal from '../../components/shared/MoreInfoModal';
import MoreInfo from './../../AppData/Strings/MoreInfo.json';
import MoreInfoLink from './MoreInfoLink';
import BasicModal from '../../theme/wrappers/BasicModal';

export default function ServicesAndPrograms({ houseInfo }) {
  const displayServices = ServicesData.slice(0, 8);
  const displayMoreServices = ServicesData.slice(8, ServicesData.length);

  return (
    <div className="row mt-3">
      <Text XX_large bold className="mb-4">
        {MoreInfo.HOUSE_DETAILS.servicesAndPrograms.title}
      </Text>
      <div className="col-12 col-sm-12 col-md-4">
        <Text extra_large bold className="mb-4">
          {MoreInfo.HOUSE_DETAILS.inHouse.title}
        </Text>
        <CheckedList
          returnedValues={houseInfo.servicesPrograms.inHouse}
          comparisonList={displayServices}
          type={'single'}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-4">
        <div>
          <Text extra_large bold className="mb-4">
            {MoreInfo.HOUSE_DETAILS.offSite.title}
            <span>
              <MoreInfoLink
                icon="info-circle"
                target={MoreInfo.HOUSE_DETAILS.offSite.id}
                text=""
                hideText={true}
              />
            </span>
          </Text>
        </div>

        <MoreInfoModal data={MoreInfo.HOUSE_DETAILS.offSite} />
        <CheckedList
          returnedValues={houseInfo.servicesPrograms.offSite}
          comparisonList={displayServices}
          type={'single'}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-4">
        <Text extra_large bold className="mb-4">
          {MoreInfo.HOUSE_DETAILS.additionalWalking.title}
        </Text>
        <CheckedList
          returnedValues={houseInfo.servicesPrograms.additionWithinWalking}
          comparisonList={AdditionalWalkableServicesData}
          type={'single'}
        />
        <Text>
          {houseInfo.applicationProcess.otherWalkablePlaces &&
            houseInfo.applicationProcess.otherWalkablePlaces}
        </Text>
      </div>
      <BasicModal
        toggleText={MoreInfo.HOUSE_DETAILS.servicesAndPrograms.toggleText}
        toggleIcon={['fas', 'angle-right']}
        title={MoreInfo.HOUSE_DETAILS.servicesAndPrograms.title}
        id={MoreInfo.HOUSE_DETAILS.servicesAndPrograms.id}
        label={MoreInfo.HOUSE_DETAILS.servicesAndPrograms.label}
      >
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4">
            <Text extra_large className="mb-4">
              {MoreInfo.HOUSE_DETAILS.inHouse.title}
            </Text>
            <CheckedList
              returnedValues={houseInfo.servicesPrograms.inHouse}
              comparisonList={displayServices.concat(displayMoreServices)}
              type={'single'}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-4">
            <Text extra_large className="mb-4">
              {MoreInfo.HOUSE_DETAILS.offSite.title}
            </Text>
            <CheckedList
              returnedValues={houseInfo.servicesPrograms.offSite}
              comparisonList={displayServices.concat(displayMoreServices)}
              type={'single'}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-4">
            <Text extra_large className="mb-4">
              {MoreInfo.HOUSE_DETAILS.additionalWalking.title}
            </Text>
            <CheckedList
              returnedValues={houseInfo.servicesPrograms.additionWithinWalking}
              comparisonList={AdditionalWalkableServicesData}
              type={'single'}
            />
          </div>
        </div>
      </BasicModal>
    </div>
  );
}
