import React, { useState, useEffect } from 'react';
import { useGSModal, useMobile } from '../../../utils/hooks';
import DesktopPageInfo from '../../../AppData/Strings/GuidedSearchDesktop.json';
import MobilePageInfo from '../../../AppData/Strings/GuidedSearchMobile.json';
import Questions from '../../../AppData/Strings/GuidedSearchQuestions.json';
import GSHeaders from '../../../AppData/Strings/GuidedSearchHeaders.json';
import { Text, LinkButton } from '../../../theme';
import { LinkButtonWithIcon } from '../../shared/LinkButtonWithIcon';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GSRadioQuestion } from './GSRadioQuestion';
import { GSTextFieldQuestion } from './GSTextFieldQuestion';
import { GSDropdownQuestion } from './GSDropdownQuestion';
import { GSDynamicDropdownQuestion } from './GSDynamicDropdownQuestion';
import './GSQuestions.scss';
import { useSearch } from '../../../utils/useSearch';
import { RolesData } from '../../../AppData/GetHelpConstants/Roles';
import {
  LanguagesData,
  languagesWithoutEnglish,
} from '../../../AppData/GetHelpConstants/Languages';
import { CriminalBackgroundData } from '../../../AppData/GetHelpConstants/CriminalBackground';
import { GuidedSearchSmokingOptions } from '../../../AppData/GetHelpConstants/Smoking';
import { createOptions } from '../../../utils/createDropdownOptions';
import { initialSearchState } from '../../../features/searchSlice';
import { setSearchState } from '../../../features/searchSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import store from '../../../store';
import { cloneDeep } from 'lodash';
import ReactGA from 'react-ga4';
import { createGSAnalytics } from '../../../utils/analytics';

export function GSQuestions() {
  const [pageInfo, setPageInfo] = useState(null);
  const [showCollapse, setShowCollapse] = useState(false);
  const { watch, reset } = useFormContext();
  const { isMobile } = useMobile();
  const { onSubmit } = useSearch();
  const page = useSelector((state) => state.guidedSearch.utilityStates.page);
  const speakEnglish = useSelector((state) => state.guidedSearch.searchFields.speakEnglish);
  const applyingForSelf = useSelector((state) => state.guidedSearch.utilityStates.applyingForSelf);
  const visible = useSelector((state) => state.guidedSearch.utilityStates.modalVisible);
  const { changePageUp, changePageDown, gotoPage, onGSModalClose, buildSearchData } = useGSModal();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!visible) {
      reset();
      setPageInfo(null);
    }
  }, [visible, reset]);

  useEffect(() => {
    const findPage = (element) => element.page == page;
    let tempPageInfo = isMobile
      ? cloneDeep(MobilePageInfo.MOBILE_PAGES.find(findPage))
      : cloneDeep(DesktopPageInfo.DESKTOP_PAGES.find(findPage));
    let headerName = tempPageInfo.header;
    const findHeader = (element) => element.name == headerName;
    tempPageInfo.headerInfo = GSHeaders.HEADERS.find(findHeader);
    setPageInfo(tempPageInfo);
  }, [isMobile, page]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (pageInfo.QUESTIONS) {
        const findQuestion = (element) => {
          if (element.viewable) {
            if (element.viewable.question === name) {
              return true;
            }
          }

          return false;
        };
        let questionIndex = pageInfo.QUESTIONS.findIndex(findQuestion);
        if (questionIndex != -1) {
          let tempPageInfo = cloneDeep(pageInfo);
          if (tempPageInfo.QUESTIONS[questionIndex].viewable.answer === value[name]) {
            tempPageInfo.QUESTIONS[questionIndex].viewable.show = true;
          } else {
            tempPageInfo.QUESTIONS[questionIndex].viewable.show = false;
          }
          setPageInfo(tempPageInfo);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [pageInfo, watch]);

  const backButton = () => {
    if (pageInfo.previous) {
      const previousQuestion = pageInfo.previous[0].question;
      const selectedAnswer = store.getState().guidedSearch.searchFields[previousQuestion];
      let previousPage = pageInfo.page - 1;
      if (selectedAnswer === '') {
        previousPage = pageInfo.previous[0].previous_page;
      } else {
        pageInfo.previous.map((previousPageInfo) => {
          if (previousPageInfo.answer === selectedAnswer || selectedAnswer === '') {
            previousPage = previousPageInfo.previous_page;
          }
        });
      }
      gotoPage(previousPage);
    } else {
      changePageDown(page);
    }
  };

  const nextButton = () => {
    if (pageInfo.next) {
      const nextQuestion = pageInfo.QUESTIONS[0].name;
      const selectedAnswer = store.getState().guidedSearch.searchFields[nextQuestion];
      let nextPage = pageInfo.page + 1;
      if (selectedAnswer === '') {
        nextPage = pageInfo.next[0].next_page;
      } else {
        pageInfo.next.map((nextPageInfo) => {
          if (nextPageInfo.answer === selectedAnswer || selectedAnswer === '') {
            nextPage = nextPageInfo.next_page;
          }
        });
      }
      gotoPage(nextPage);
    } else {
      changePageUp(page);
    }
  };

  const toggleCollapse = () => {
    setShowCollapse(!showCollapse);
  };

  const header = () => {
    if (applyingForSelf) {
      return pageInfo.headerInfo.text.myself;
    } else {
      return pageInfo.headerInfo.text.other != null
        ? pageInfo.headerInfo.text.other
        : pageInfo.headerInfo.text.myself;
    }
  };

  const getOptions = (questionType) => {
    switch (questionType) {
      case 'describeYou': {
        return RolesData;
      }
      case 'gender': {
        return createOptions('gender');
      }
      case 'primaryLanguage': {
        if (speakEnglish === 'speakEnglish_no') {
          return languagesWithoutEnglish;
        } else {
          return LanguagesData;
        }
      }
      case 'convictions': {
        return CriminalBackgroundData;
      }
      case 'whichNicotineProducts': {
        return GuidedSearchSmokingOptions;
      }
    }
  };

  const createQuestion = (question, index) => {
    const findQuestion = (element) => element.name == question.name;
    let questionInfo = Questions.ALL_QUESTIONS.find(findQuestion);

    let viewable = true;
    if (question.viewable) {
      viewable = question.viewable.show;
    }

    switch (questionInfo?.type) {
      case 'radio':
        return <GSRadioQuestion key={`gsradio-${index}`} question={questionInfo} index={index} />;
      case 'dropdown':
        return (
          <GSDropdownQuestion
            key={`gsdropdown-${index}`}
            question={questionInfo}
            index={index}
            viewable={viewable}
            options={getOptions(questionInfo.name)}
          />
        );
      case 'dynamicDropdown':
        return (
          <GSDynamicDropdownQuestion
            key={`gsdynamicdropdown-${index}`}
            question={questionInfo}
            index={index}
          />
        );
      case 'textField':
        return (
          <GSTextFieldQuestion
            key={`gstextfield-${index}`}
            question={questionInfo}
            index={index}
            viewable={viewable}
          />
        );
    }
  };

  return (
    <>
      <div className="row text-center text-white">
        <div className={`col ${isMobile ? 'col-12' : 'offset-2 col-8'} text-center my-5`}>
          <Text extra_large white className="pb-3">
            {pageInfo && header()}
          </Text>
          {pageInfo && pageInfo.headerInfo.text.moreInfo && (
            <>
              <FontAwesomeIcon icon={['fas', 'question-circle']} />
              <a
                className="text-white"
                href="#collapseMoreInfo"
                onClick={toggleCollapse}
                role="button"
                aria-expanded="false"
                aria-controls="collapseMoreInfo"
              >
                {pageInfo && pageInfo.headerInfo.text.moreInfo}
              </a>
              <div className={`collapse ${showCollapse && 'show'} pt-3" id="collapseMoreInfo`}>
                <div className="card card-body bg-primary">
                  {pageInfo && pageInfo.headerInfo.text.moreInfoText}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="col col-11">
        <div>
          {pageInfo && pageInfo.QUESTIONS.map((question, index) => createQuestion(question, index))}
        </div>
      </div>
      <div
        className={`d-flex flex-wrap justify-content-center ${isMobile ? 'pt-3' : 'pt-5'}`}
        width="150px"
      >
        {page > 1 && (
          <div className="button-wrapper order-md-1 me-md-4">
            <LinkButtonWithIcon
              link=""
              onClick={backButton}
              label="Back"
              icon={['far', 'angle-double-left']}
              height="45px"
              width="100%"
              radius="2px"
              lineHeight="35px"
              iconPosition="left"
            />
          </div>
        )}
        <div className="button-wrapper ms-auto order-md-3" width="150px">
          <LinkButtonWithIcon
            link=""
            onClick={nextButton}
            label="Next"
            icon={['far', 'angle-double-right']}
            height="45px"
            width="100%"
            radius="2px"
            lineHeight="35px"
            iconPosition="right"
          />
        </div>
        {page > 1 && (
          <div className={`order-md-2 pt-3 pt-md-0 ${isMobile && 'col-12'}`}>
            <LinkButton
              to="/houses/"
              onClick={(e) => {
                e.preventDefault();
                onGSModalClose();
                const updatedSearchData = buildSearchData(initialSearchState);
                dispatch(setSearchState(updatedSearchData));
                ReactGA.event({
                  category: 'viewMatchingRecoveryHouses',
                  action: 'viewMatchingRecoveryHouses',
                  label: 'gsQuestionsViewMatchingRecoveryHouses',
                });
                createGSAnalytics(store.getState().guidedSearch.searchFields);
                onSubmit(updatedSearchData);
              }}
              type="transparent"
              border="2px solid black"
              width="100%"
              height={!isMobile ? '45px' : undefined}
              border-radius="2px"
              lineheight="32px"
            >
              View Matching Recovery Houses
            </LinkButton>
          </div>
        )}
      </div>
    </>
  );
}
