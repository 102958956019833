import { useState, useEffect } from 'react';
import { getDistance, convertDistance } from 'geolib';

const useDistanceLabel = (address, searchLocation) => {
  const [distLabel, setDistLabel] = useState('Loading');

  const calculateDistanceLabel = async () => {
    if (
      !address ||
      !searchLocation ||
      !searchLocation.lat ||
      !searchLocation.long ||
      !address.latitude ||
      !address.longitude
    ) {
      return '';
    }
    if (address.privateAddress) {
      return address?.privateAddress ? '\u2022 Address hidden for privacy' : '';
    }
    const dist = getDistance(
      { latitude: searchLocation.lat, longitude: searchLocation.long },
      { latitude: address.latitude, longitude: address.longitude },
    );
    return `\u2022 ${Math.round(convertDistance(dist, 'mi')).toString()} miles from location`;
  };

  useEffect(() => {
    calculateDistanceLabel().then((label) => {
      setDistLabel(label);
    });
  }, [address, searchLocation]);

  return distLabel;
};

export { useDistanceLabel };
