import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Strings from '../../AppData/Strings/HousesStrings';
import { CurrencyInput } from '../../components';
import './AverageRentDropdown.scss';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setSearchValue } from '../../features/searchSlice';

export function AverageRentDropdown({ toggleOnChangeState }) {
  const avgRentDropdown = useRef();
  const [dropdownIndicator, setDropdownIndicator] = useState(false);
  const dropdownIndicatorIcon = dropdownIndicator ? 'caret-up' : 'caret-down';
  const dispatch = useDispatch();
  const avgCostMin = useSelector((state) => state.search.avgCostMin);
  const avgCostMax = useSelector((state) => state.search.avgCostMax);
  const [minCost, setMinCost] = useState(avgCostMin);
  const [maxCost, setMaxCost] = useState(avgCostMax);

  const handleClick = (e) => {
    if (avgRentDropdown.current && avgRentDropdown.current.contains(e.target)) {
      return;
    }
    setDropdownIndicator(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    setMinCost(avgCostMin);
    setMaxCost(avgCostMax);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMinCost(avgCostMin);
    setMaxCost(avgCostMax);
  }, [avgCostMin, avgCostMax, setMinCost, setMaxCost]);

  const onMinChange = (minValue) => {
    setMinCost(minValue);
    dispatch(setSearchValue({ value: minValue, field: 'avgCostMin' }));
    toggleOnChangeState();
  };

  const onMaxChange = (maxValue) => {
    setMaxCost(maxValue);
    dispatch(setSearchValue({ value: maxValue, field: 'avgCostMax' }));
    toggleOnChangeState();
  };

  const handleSlider = (sliderValues) => {
    setMinCost(sliderValues[0]);
    setMaxCost(sliderValues[1]);
  };

  const handleAfterChange = (e) => {
    onMinChange(e[0]);
    onMaxChange(e[1]);
  };

  return (
    <div ref={avgRentDropdown} className="avg-rent-container">
      <button
        className="rent-button"
        type="button"
        onClick={() => setDropdownIndicator(!dropdownIndicator)}
      >
        {Strings.Houses.AVERAGE_RENT.text}&nbsp;&nbsp;
        <FontAwesomeIcon className="icon-color" icon={['fas', dropdownIndicatorIcon]} />
      </button>
      {dropdownIndicator ? (
        <div className="rent-card">
          <div className="title mb-2">{Strings.Houses.PRICE_RANGE.text}</div>
          <Slider
            className="mb-5"
            range
            min={0}
            max={1600}
            step={100}
            marks={{ 0: '$0', 1600: '$1600' }}
            value={[minCost, maxCost]}
            tipFormatter={(value) => `$ ${value}`}
            tipsProps={{ placement: 'top', visible: true }}
            allowCross={false}
            onChange={handleSlider}
            onAfterChange={(e) => handleAfterChange(e)}
          />
          <span className="title">{Strings.Houses.MIN_RENT.text}</span>
          <CurrencyInput
            onChange={(e) => {
              if (onMinChange) {
                onMinChange(e.target.value);
              }
            }}
            value={minCost}
          />
          <span className="title">{Strings.Houses.MAX_RENT.text}</span>
          <CurrencyInput
            onChange={(e) => {
              if (onMaxChange) {
                onMaxChange(e.target.value);
              }
            }}
            value={maxCost}
          />
        </div>
      ) : null}
    </div>
  );
}
