import React from 'react';
import { Text } from '../../theme';
import CheckedList from './CheckedList';
import { DepositData, DueDateData } from '../../AppData/GetHelpConstants/Deposit';
import { PaymentMethodsData } from '../../AppData/GetHelpConstants/Payment';
import { AmenitiesData } from '../../AppData/GetHelpConstants/Amenities';
import ItemList from './ItemList';
import { useMobile } from '../../utils/hooks';
import BasicModal from '../../theme/wrappers/BasicModal.js';
import MoreInfo from './../../AppData/Strings/MoreInfo.json';
import './CheckedList.scss';

export default function Rent({ houseInfo }) {
  const { isMobile } = useMobile();
  const displayAmenities = AmenitiesData.slice(0, 10);
  const displayMoreAmenities = AmenitiesData.slice(10, AmenitiesData.length);

  const getDueDate = (givenValue) => {
    const findDueDate = (element) => element.id == givenValue;
    let dueDate = DueDateData.find(findDueDate);

    return dueDate.label;
  };

  return (
    <>
      <div className="row mt-3">
        <Text XX_large bold className="mb-4">
          Rent
        </Text>
        <div className="col">
          <CheckedList
            returnedValues={houseInfo.rent.general}
            comparisonList={DepositData}
            type={isMobile ? 'single' : 'inline'}
          />
        </div>
      </div>
      {houseInfo.bedTypes.accommodations.length > 0 && (
        <div className="row mb-2">
          <Text XX_large extraBold className="mt-3">
            From $100/month
          </Text>
          <Text gray className="mb-3">
            See specific bed prices below under Bed Types
          </Text>
        </div>
      )}
      <div className="row">
        <Text large bold className="mb-3">
          Amenities
        </Text>
        <div className="row">
          <CheckedList
            returnedValues={houseInfo.rent.amenities}
            comparisonList={displayAmenities}
            type={isMobile ? 'single' : ''}
          />
        </div>
        <BasicModal
          toggleText={MoreInfo.HOUSE_DETAILS.amenities.toggleText}
          toggleIcon={['fas', 'angle-right']}
          title={MoreInfo.HOUSE_DETAILS.amenities.title}
          id={MoreInfo.HOUSE_DETAILS.amenities.id}
          label={MoreInfo.HOUSE_DETAILS.amenities.label}
        >
          <div className="row">
            <CheckedList
              returnedValues={houseInfo.rent.amenities}
              comparisonList={displayAmenities.concat(displayMoreAmenities)}
              type={isMobile ? 'single' : 'modal'}
            />
          </div>
        </BasicModal>
      </div>
      {houseInfo.rent.paymentOptions.length > 0 && (
        <div className="row my-3">
          <Text large bold className="mb-3">
            Accepted Payment Options
          </Text>
          {houseInfo.rent.paymentOptions.map((paymentMethod, index) => (
            <div key={index} className={isMobile ? 'col-12' : 'col-6'}>
              <ItemList
                key={index}
                item={paymentMethod}
                list={PaymentMethodsData}
                bullet={true}
                singleCol={isMobile ? true : false}
              />
            </div>
          ))}
        </div>
      )}
      {houseInfo.rent.depositInfo.amountDue && (
        <div className="row mb-5">
          <Text large bold className="mb-3">
            Deposit Information
          </Text>
          <div className={isMobile ? 'col-12 mb-3' : 'col-6'}>
            <span className="fw-bold">Amount due: $</span>
            {houseInfo.rent.depositInfo.amountDue}
          </div>

          <div className={isMobile ? 'col-12' : 'col-6'}>
            <span className="fw-bold">Due date:&nbsp;</span>
            {getDueDate(houseInfo.rent.depositInfo.dueDate)}
          </div>
        </div>
      )}
      {houseInfo.rent.additionalFeeAmount && (
        <div className="row">
          <Text large bold className="mb-3">
            Additional Fees
          </Text>
          <div className={isMobile ? 'col-12 mb-3' : 'col-6'}>
            <span className="fw-bold">Amount: $</span>
            {houseInfo.rent.additionalFeeAmount}
          </div>
        </div>
      )}
    </>
  );
}
