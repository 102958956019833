export function goToLink(event, url) {
  var type = event.type;

  if (type === 'click' || (type === 'keydown' && event.key === 'Enter')) {
    if (url) {
      window.open(url, '_blank');
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
