import React from 'react';
import { Text } from '../../../theme';
import Strings from '../../../AppData/Strings/HousesStrings';
import { AmenitiesData } from '../../../AppData/GetHelpConstants/Amenities';
import './SearchFilters.scss';
import { Checkbox } from '../../../components';

export function AmenitiesSearchFilters({ searchStateCopy, setSearchStateCopy }) {
  return (
    <div className="accordion-item search-border">
      <div className="accordion-header" id="headingFive">
        <button
          type="button"
          className="accordion-button collapsed p-0 search-format"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFive"
          aria-expanded="false"
          aria-controls="collapseFive"
        >
          <Text bold large className="text-left mb-2 col">
            {Strings.Houses.AMENITIES.text}
          </Text>
        </button>
      </div>
      <div
        id="collapseFive"
        className="accordion-collapse collapse"
        aria-labelledby="headingFive"
        data-bs-parent="#searchFiltersAccordion"
      >
        <div className="row accordion-body">
          <div className="row pe-2">
            {AmenitiesData.map((service, index) => (
              <Checkbox
                label={service.label}
                key={`${service.id}-${index}`}
                className={'my-3 col-12 col-lg-6'}
                onChange={(e) => {
                  const tempSearchData = { ...searchStateCopy.amenities };
                  tempSearchData[service.id] = e.target.checked;
                  setSearchStateCopy({ ...searchStateCopy, amenities: tempSearchData });
                }}
                isDefaultChecked={searchStateCopy.amenities[service.id] || false}
              />
            ))}
          </div>
        </div>
      </div>
      <hr></hr>
    </div>
  );
}
