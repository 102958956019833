import Questions from '../AppData/Strings/GuidedSearchQuestions.json';
import ReactGA from 'react-ga4';

export const createGSAnalytics = (obj) => {
  if (obj) {
    let guidedSearchAnalytics = Object.keys(obj)
      .map((key) => {
        let questionObj = {};
        if (obj[key].label) {
          questionObj = {
            question: key,
            answer: obj[key].label,
          };
        } else {
          questionObj = {
            question: key,
            answer: obj[key],
          };
        }

        return questionObj;
      })
      .filter((question) => question.answer !== '' || undefined)
      .map((question) => {
        const findQuestion = (element) => element.name == question.question;
        let questionInfo = Questions.ALL_QUESTIONS.find(findQuestion);
        if (questionInfo) {
          if (questionInfo.label.other) {
            question.question = questionInfo.label.other;
          } else {
            question.question = questionInfo.label.myself;
          }
        }

        return question;
      });

    guidedSearchAnalytics.forEach((question) => {
      if (question && question.question && question.answer) {
        if (Array.isArray(question.answer)) {
          question.answer.forEach((answer) => {
            question.answer = answer;
            ReactGA.event('guidedSearchQA', {
              question: question.question,
              answer: question.answer.label,
            });
          });
        } else {
          ReactGA.event('guidedSearchQA', { question: question.question, answer: question.answer });
        }
      }
    });

    return guidedSearchAnalytics;
  }
};
