import styled from 'styled-components';
import colors from '../../scss/color.module.scss';
const { textwhite } = colors;
import getImageByKey from '../../utils/getImages';
import { handleFontsize } from './Text';

function chooseImage(props) {
  if (props.image.includes('url')) {
    return props.image.replace('url=', '');
  } else {
    return getImageByKey(props.image);
  }
}

const HeroImage = styled.div`
  background: url(${(props) => chooseImage(props)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  color: ${textwhite};
  font-size: ${(props) => handleFontsize(props)};
  font-weight: 400;
  width: 100%;
  box-shadow: inset 0 0 0 2000px rgba(0, 66, 102, 0.5);
`;

export { HeroImage };
