import { createSlice } from '@reduxjs/toolkit';

export const initialGuidedSearchState = {
  searchFields: {
    whoSearch: '',
    firstTime: '',
    describeYou: '',
    currentRes: '',
    nearLocation: '',
    cityStateToBeHoused: '',
    gender: '',
    LGBTFriendly: '',
    speakEnglish: '',
    primaryLanguage: '',
    pregnant: '',
    under18: '',
    undergoingMTA: '',
    wheelchairAccessible: '',
    convictions: '',
    useNicotineProducts: '',
    whichNicotineProducts: '',
  },
  utilityStates: {
    gsModal: null,
    page: 0,
    applyingForSelf: true,
    modalVisible: false,
  },
};

export const guidedSearchSlice = createSlice({
  name: 'guidedSearch',
  initialState: initialGuidedSearchState,
  reducers: {
    setGuidedSearchValue: (state, action) => {
      state.searchFields[action.payload.field] = action.payload.value;
    },
    setGuidedSearchUtilityValue: (state, action) => {
      state.utilityStates[action.payload.field] = action.payload.value;
    },
    resetState: () => {
      return initialGuidedSearchState;
    },
  },
});

export const { setGuidedSearchValue, setGuidedSearchUtilityValue, resetState } =
  guidedSearchSlice.actions;

export default guidedSearchSlice.reducer;
