import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Text } from '../../theme';
import { ResourceContentType, FavoriteButton } from '../../components';
import colors from '../../scss/color.module.scss';
const { headerfooterbg } = colors;
import './ResourceCard.scss';

export default function ResourceCard({ resource }) {
  const removeParagraphTags = (text) => {
    return text.substr(3, text.length - 7);
  };

  return (
    <div
      className={classnames('mb-3', 'resource-card', {
        'border border-navy border-2': resource.recommendation,
      })}
      key={`resourceCard${resource.id}`}
    >
      <div className="mx-3 pt-3">
        <div className="d-block mb-5" key={`org${resource.id}`}>
          <div className="mb-2 resource-title" key={`label${resource.id}`}>
            <Text bold large>
              {resource.asset.label}
            </Text>
          </div>
          <div className="resource-desc" key={`desc${resource.id}`}>
            <Text
              className="mb-0"
              dangerouslySetInnerHTML={{ __html: removeParagraphTags(resource.asset.description) }}
            ></Text>
          </div>
          <Text gray italic ellipsis className="mt-2">
            {resource.asset.organization}
          </Text>
        </div>
        <div className="pt-2">
          <div className="d-flex justify-content-end bottom-right">
            <Text bold large className="align-self-center">
              {resource.recommendation && (
                <FontAwesomeIcon icon={['fas', 'badge-check']} size="lg" color={headerfooterbg} />
              )}
            </Text>
            <Text bold large className="align-self-center">
              <FavoriteButton domain={'Resource'} favoriteKey={resource.id} defaultValue={true} />
            </Text>
            {resource.asset.asset_type != 'Link' && (
              <div className="">
                <ResourceContentType content_type={resource.asset.asset_type} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
