/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../theme';
import { FavoriteButton } from '../../components';
import { RHLink } from '../../theme';
import './RHInfoWindow.scss';
import { useDistanceLabel } from '../../utils/hooks/useDistanceLabel';
import { StatusIndicator } from '../../components/shared/StatusIndicator';
import Gender from '../HouseDetail/Gender';
import { RHImage } from '../../theme/wrappers/RHImage';

export function RHInfoWindow({
  houseId,
  house,
  setSelectedHouse,
  isSelectedHouse,
  searchLocation,
}) {
  const distanceLabel = useDistanceLabel(house.address, searchLocation);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classnames('rh-info-window', 'mb-4', 'pe-2', {
        'rh-info-window-selected-bg': isSelectedHouse,
      })}
      onClick={() => {
        setSelectedHouse(houseId);
      }}
    >
      <div className="row mx-0">
        <div className="col-4 ps-0 pe-2">
          <RHImage image={house.images[0]} height={'116px'} alt={house.name} />
        </div>
        <div className={classnames('col-8', 'px-0', 'mx-0', { 'bottom-line': isSelectedHouse })}>
          <div className="row pt-3 px-0 mx-0">
            <div className="col-1 px-0 me-0">
              <FavoriteButton
                domain={'House'}
                favoriteKey={houseId}
                defaultValue={true}
                backgroundColor={'mediumgray'}
              />
            </div>
            <Text extra_large className="col-11 ps-0 pe-0" lineHeight="1.5rem">
              {house.name}
            </Text>
          </div>

          <Text className="pt-2" gray>
            {house.address.city && house.address.state && (
              <>
                <span className="fw-bold">{`${house.address.city}, ${house.address.state} `}</span>
              </>
            )}
            <span>{distanceLabel}</span>
          </Text>
          <Text gray className="ps-0">
            {house.beds != null && house.beds != 0 && `${house.beds} beds`}
            {house.baths != null &&
              house.baths != 0 &&
              house.beds != null &&
              house.beds != 0 &&
              ` \u2022 `}
            {house.baths != null && house.baths != 0 && `${house.baths} baths`}
          </Text>
        </div>
      </div>
      {isSelectedHouse && (
        <div>
          {house.rent.rentFrom && house.rent.rentFrom !== 0 && house.genderType && (
            <div className="row row-cols-2 mx-0 pt-4">
              {house.rent.rentFrom && house.rent.rentFrom !== 0 && (
                <>
                  <Text>
                    <FontAwesomeIcon icon={['far', 'dollar-sign']} size="lg" />
                    From ${house.rent.rentFrom}/month
                  </Text>
                </>
              )}
              {house.genderType && (
                <Text>
                  <Gender genderServed={house.genderType} iconsSize={'sm'} />
                </Text>
              )}
            </div>
          )}
          <div className="row row-cols-2 mx-0 pt-4">
            <StatusIndicator
              status={house.status}
              updatedAt={house.updatedAt}
              type={'leftJustify'}
              size={'small'}
            />
            <RHLink className="mt-2 text-end" to={`houseDetail?id=${houseId}`}>
              {'More details'}&nbsp;&nbsp;
              <FontAwesomeIcon icon={['far', 'angle-double-right']} />
            </RHLink>
          </div>
        </div>
      )}
    </div>
  );
}
