import React, { useEffect } from 'react';
import { Text } from '../../../theme';
import { useGSModal, useMobile } from '../../../utils/hooks';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import {
  setGuidedSearchValue,
  setGuidedSearchUtilityValue,
} from '../../../features/guidedSearchSlice';
import { useSelector, useDispatch } from 'react-redux';

export function GSRadioQuestion({ question, index }) {
  const { isMobile } = useMobile();
  const { register } = useFormContext();
  const { questionLabel } = useGSModal();
  const fieldValue = useSelector((state) => state.guidedSearch.searchFields[question.name]);
  const applyingForSelf = useSelector((state) => state.guidedSearch.utilityStates.applyingForSelf);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fieldValue) {
      let radiobtn = document.getElementById(fieldValue.id);
      radiobtn.checked = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  const handleChecked = (e) => {
    const value = document.querySelector(`input[name=${question.name}]:checked`).id;
    const label = e.target.parentElement.textContent;
    switch (e.target.id) {
      case 'whoSearch_myself':
        dispatch(setGuidedSearchUtilityValue({ field: 'applyingForSelf', value: true }));
        break;
      case 'whoSearch_someone_else':
        dispatch(setGuidedSearchUtilityValue({ field: 'applyingForSelf', value: false }));
        break;
    }
    dispatch(setGuidedSearchValue({ field: question.name, value: { id: value, label } }));
  };

  return (
    <div key={question.name + index}>
      <div className="row mb-5">
        <div className={isMobile ? 'col-12 pb-3' : 'col-6'}>
          <Text white large>
            {questionLabel(question, applyingForSelf)}
          </Text>
        </div>
        <div className={isMobile ? 'col-12' : 'col-5'}>
          <div className="row">
            <div className="col">
              {question.OPTIONS.map((option) => {
                return (
                  <div
                    key={`radio_${option.value}`}
                    className={`form-check 
                  ${!isMobile ? 'form-check-inline' : 'pb-3'} 
                  radioItem 
                  m-0 
                  p-0
                  ${option.hideable != null && applyingForSelf ? 'd-none' : ''}`}
                  >
                    <input
                      className="form-check-input GSRadioButton"
                      type="radio"
                      name={question.name}
                      id={`${question.name}_${option.value}`}
                      onClick={handleChecked}
                      value={option.value && option.value}
                      {...register(`${question.name}`)}
                    />
                    <label
                      className={classNames('form-check-label', 'pe-5', {
                        'ps-4': !isMobile && `${question.name}_${option.value}` === 'firstTime_no',
                      })}
                      htmlFor={`${question.name}_${option.value}`}
                    >
                      {option.text}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
