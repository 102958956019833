export const ApplicationConstants = {
  APPLICATION_REQUIRED: 'APPLICATION_REQUIRED',
  SCREENING_REQUIRED: 'SCREENING_REQUIRED',
  URINE_TEST_REQUIRED: 'URINE_TEST_REQUIRED',
  BACKGROUND_CHECK_REQUIRED: 'BACKGROUND_CHECK_REQUIRED',
};

export const ApplicationData = [
  {
    id: ApplicationConstants.APPLICATION_REQUIRED,
    label: 'Application Required',
  },
  {
    id: ApplicationConstants.SCREENING_REQUIRED,
    label: 'Screening Required',
  },
  {
    id: ApplicationConstants.URINE_TEST_REQUIRED,
    label: 'Resident must complete a urine analysis and/or breathalyzer test',
  },
  {
    id: ApplicationConstants.BACKGROUND_CHECK_REQUIRED,
    label: 'Background Check Required',
  },
];
