import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from './../../scss/color.module.scss';
const { inputlines, textgray, headerfooterbg } = colors;
import './TrueFalseText.scss';
import { Text } from '../../theme';

export function TrueFalseText(props) {
  const { status, text, icon, size, transportation } = props;

  return (
    <>
      <div className="row">
        <div className="fixed">
          {status === false ? (
            icon ? (
              <FontAwesomeIcon
                icon={['fas', icon]}
                color={inputlines}
                size={size == 'small' ? 'sm' : 'lg'}
              />
            ) : (
              <FontAwesomeIcon
                icon={['fal', 'times']}
                color={inputlines}
                size={size == 'small' ? 'sm' : 'lg'}
              />
            )
          ) : icon ? (
            <FontAwesomeIcon
              icon={['fas', icon]}
              color={headerfooterbg}
              size={size == 'small' ? 'sm' : 'lg'}
            />
          ) : (
            <FontAwesomeIcon
              icon={['fal', 'check']}
              color={textgray}
              size={size == 'small' ? 'sm' : 'lg'}
            />
          )}
        </div>
        <Text
          regular={size == 'small'}
          large={size != 'small'}
          className={`col ${transportation ? 'ps-2' : 'ps-0'} 
          ${status === false ? 'text-decoration-line-through' : ''}`}
        >
          {text}
        </Text>
      </div>
    </>
  );
}
