import React from 'react';
import { Link } from 'react-router-dom';

export function TextWithLink({ text, path, linkText }) {
  return (
    <div>
      {text ? `${text}` : ''}&nbsp;&nbsp;
      <Link to={path} className="text-text">
        {linkText}
      </Link>
    </div>
  );
}
