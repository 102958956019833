/* eslint-disable max-len */
import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import RecStrings from '../../AppData/Strings/RecommendedResourcesString.json';
import { Button, Text } from '../../theme';
import './RecommendedResources.scss';
import { CloseButton } from '../../components';
import { ErrorText } from '../../theme/wrappers/ErrorText';
import { ErrorMessage } from '@hookform/error-message';
import colors from '../../scss/color.module.scss';
const { inputlines } = colors;

const createRecommendedResource = async (request) => {
  const formData = new FormData();

  formData.append('name', request.name);
  formData.append('email', request.email);
  formData.append('message', request.message);
  formData.append('desc', request.desc);
  if (request.file.length > 0) {
    formData.append('recommended_resource_file', request.file[0]);
  }

  const { data: response } = await axios.post(
    `${process.env.REACT_APP_API_ROOT}/api/assets/recommended_resource/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response;
};

export function RecommendedResources({ refRecResources, modal }) {
  const [submissionError, setSubmissionError] = useState('');
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { mutate } = useMutation(createRecommendedResource, {
    onSuccess: () => {
      setSubmissionSuccess(true);
      setSubmissionError('');
    },
    onError: (error) => {
      if (error.response.status == '400') {
        const errMsg = error.response.data;
        if (errMsg.email) {
          setSubmissionError('Submission error: Invalid email address');
        } else {
          setSubmissionError('Submission error');
        }
      } else {
        setSubmissionError('Submission error');
      }
    },
  });

  const getField = (obj) => {
    const name = `info.${obj.name}`;

    switch (obj.type) {
      case 'text-area':
        return (
          <textarea
            {...register(name, {
              required: { value: obj.required, message: `${obj.label} is required` },
            })}
            className="form-control"
            id={name}
            rows="6"
          ></textarea>
        );
      case 'email':
        // eslint-disable-next-line no-case-declarations
        const emailRegex =
          // eslint-disable-next-line no-control-regex
          /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

        return (
          <input
            {...register(name, {
              required: { value: obj.required, message: `${obj.label} is required` },
              pattern: {
                value: emailRegex,
                message: 'invalid email address',
              },
            })}
            id={obj.name}
            type="text"
            className="form-control"
            aria-describedby={obj.label}
          />
        );
      case 'file':
        return (
          <input
            {...register(name, {
              required: { value: obj.required, message: `${obj.label} is required` },
            })}
            id={obj.name}
            type={obj.type}
            className="form-control"
            aria-describedby={obj.label}
          />
        );
      default:
        return (
          <input
            {...register(name, {
              required: { value: obj.required, message: `${obj.label} is required` },
            })}
            id={obj.name}
            type={obj.type}
            className="form-control"
            aria-describedby={obj.label}
          />
        );
    }
  };

  const onSubmitOrder = (data) => {
    mutate(data.info);
  };

  return (
    <div
      className="modal fade rec-resources-container"
      ref={refRecResources}
      tabIndex="-1"
      aria-labelledby="Recommended Resources"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header border-0 mx-4 mb-0">
            <Text extra_large className="w-100 text-center">
              <FontAwesomeIcon icon={['fal', 'sliders-h']} />
              {RecStrings.REC_RESOURCES.HEADER}
            </Text>
            <CloseButton
              onClick={() => {
                setSubmissionSuccess(false);
                setSubmissionError('');
                reset();
                modal.hide();
              }}
            />
          </div>
          {submissionSuccess == true ? (
            <div className="text-center">
              <Text large>{RecStrings.REC_RESOURCES.SUCCESS_HEADER}</Text>
              <FontAwesomeIcon
                className="mt-5"
                icon={['fal', 'paper-plane']}
                size="7x"
                color={inputlines}
              />
              <Text className="mt-5">{RecStrings.REC_RESOURCES.SUCCESS_MESSAGE}</Text>
              <Button
                className="my-5"
                onClick={() => {
                  setSubmissionSuccess(false);
                  setSubmissionError('');
                  reset();
                  modal.hide();
                }}
                primary
                width="229px"
                height="36px"
                large
              >
                {RecStrings.REC_RESOURCES.CLOSE}
              </Button>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmitOrder)}>
              <div className="modal-body mx-4 mt-0 pt-0">
                <Text large className="w-100 text-center">
                  {RecStrings.REC_RESOURCES.FILL_OUT}
                </Text>
                <div className="modal-body">
                  {RecStrings.REC_RESOURCES.FIELDS.map((obj, index) => (
                    <div className="mb-3 row" key={`rec-resources-${index}`}>
                      <label
                        htmlFor={`info.${obj.name}`}
                        className={classnames(
                          'offset-sm-1',
                          'col-sm-3',
                          'col-form-label',
                          'checkout-label',
                          { required: obj.required },
                        )}
                      >
                        {obj.label}
                      </label>
                      <div className="col-sm-7">
                        {getField(obj)}
                        <ErrorMessage
                          errors={errors}
                          name={`info.${obj.name}`}
                          render={({ message }) => (
                            <ErrorText className="px-1">
                              <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                              {message}
                            </ErrorText>
                          )}
                        ></ErrorMessage>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="modal-footer border-0 mb-4 mx-4 justify-content-center">
                {submissionError && (
                  <Text className="w-100 pt-1 text-center">
                    <p className="digital-product-error">{submissionError}</p>
                  </Text>
                )}
                <Button submit primary width="229px" height="36px" large>
                  {RecStrings.REC_RESOURCES.SUBMIT}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
