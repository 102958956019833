import React, { useState } from 'react';
import store from '../../store';
import { useLocalStorage } from '../../utils/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useSearch } from '../../utils/useSearch';
import { initialSearchState, setSearchState } from '../../features/searchSlice';
import colors from '../../scss/color.module.scss';
import RHToast from '../../components/shared/RHToast';
import { Text } from '../../theme';
import { cloneDeep } from 'lodash';

export const SaveSearch = ({ title, favoriteHouses }) => {
  const [label, setLabel] = useState('');
  const [error, setError] = useState('');
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();
  const { onSubmit } = useSearch();
  const [savedSearches, setSavedSearches] = useLocalStorage('Saved Search', 'SavedSearches');
  const { buildParams } = useSearch();

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const isLabelValid = () => {
    if (label) {
      if (!savedSearches || savedSearches.length === 0) {
        return true;
      }

      let isDuplicate = false;
      savedSearches.forEach((search) => {
        if (label.toLowerCase() === Object.keys(search)[0].toLowerCase()) {
          setError('Label must be unique');
          isDuplicate = true;

          return;
        }
      });

      return !isDuplicate;
    } else {
      setError('Label cannot be empty');

      return false;
    }
  };

  const handleSaveSearch = () => {
    if (isLabelValid()) {
      if (!savedSearches || savedSearches.length === 0) {
        setSavedSearches([{ [label]: store.getState().search }]);
      } else {
        setSavedSearches((prev) => [...prev, { [label]: store.getState().search }]);
      }
      setLabel('');
      setError('');
    }
  };

  const buildURL = (searchObj) => {
    const { recoveryHousingParams } = buildParams(searchObj);

    return window.location.origin + window.location.pathname + '?' + recoveryHousingParams;
  };

  const handleCopySavedSearch = (searchObj) => () => {
    navigator.clipboard.writeText(buildURL(searchObj));
    setShowToast(true);
  };

  const handleDeleteSavedSearch = (searchLabel) => () => {
    setSavedSearches((prevState) =>
      prevState.filter((prevItem) => Object.keys(prevItem)[0] !== searchLabel),
    );
  };

  const handleLoadSavedSearch = (searchObj) => () => {
    dispatch(setSearchState(searchObj));
    onSubmit(searchObj);
  };

  const handleLoadFavoriteHouses = () => {
    const tempSearchObject = cloneDeep(initialSearchState);
    const favoriteHousesIds = Object.keys(favoriteHouses)
      .map((id) => id)
      .join(',');
    tempSearchObject.favoriteFacilities = favoriteHousesIds;
    dispatch(setSearchState(tempSearchObject));
    onSubmit(tempSearchObject);
  };

  return (
    <>
      <div className="row text-center px-4 pt-4">
        {title && <h4>{title}</h4>}
        <p>
          Save the criteria you used for this search so you can get up-to-date results next time you
          visit the website. Just enter a label for the search below and click Save.
        </p>
      </div>
      <div className="row d-flex flex-col justify-content-center px-5">
        {error && (
          <div className="row">
            <p className="m-0 p-0" style={{ color: colors.error }}>
              {error}
            </p>
          </div>
        )}
        <label htmlFor="searchLabel" className="fw-bold">
          Name
        </label>
        <div className="row mb-4 border-2 border-bottom pb-5 px-md-2">
          <div className="col-12 col-sm-9 p-0 mb-2 mb-sm-0">
            <input
              className="form-control"
              id="searchLabel"
              type="text"
              aria-label="save search label"
              value={label}
              onChange={handleLabelChange}
            />
          </div>
          <div className="col-12 col-sm-3 d-flex justify-content-center p-0">
            <button type="button" className="btn btn-primary col-10" onClick={handleSaveSearch}>
              Save
            </button>
          </div>
        </div>

        <h6 className="fw-bold mt-4 p-0">My Saved Searches</h6>
        <Text className="p-0 mb-2" small italic>
          If a house you have favorited does not appear in the results, it may have become
          unpubulished due to not being kept up to date.
        </Text>
      </div>
      <div className="pb-2">
        <table className="table table-striped table-borderless">
          <tbody>
            {favoriteHouses && (
              <tr>
                <td className="text-right">
                  <button
                    type="button"
                    className="regular-link border-0 bg-transparent"
                    data-bs-toggle="modal"
                    data-bs-target="#savedSearchModal"
                    onClick={handleLoadFavoriteHouses}
                  >
                    <FontAwesomeIcon icon={['fas', 'star']} color={colors.favorites} size="sm" />
                    My Favorite Houses
                  </button>
                </td>
              </tr>
            )}
            {!savedSearches || savedSearches.length === 0 ? (
              <tr>
                <td className="text-right" colSpan={3}>
                  No Saved Searches
                </td>
              </tr>
            ) : (
              savedSearches.map((search, i) => {
                return (
                  <tr key={i}>
                    <td className="d-flex">
                      <div className="flex-fill">
                        <button
                          type="button"
                          className="regular-link border-0 bg-transparent"
                          data-bs-toggle="modal"
                          data-bs-target="#savedSearchModal"
                          onClick={handleLoadSavedSearch(search[Object.keys(search)])}
                        >
                          {Object.keys(search)}
                        </button>
                      </div>
                      <div className="me-2">
                        <button
                          type="button"
                          className="border-0 bg-transparent"
                          onClick={handleDeleteSavedSearch(Object.keys(search)[0])}
                        >
                          <FontAwesomeIcon icon={['fas', 'trash']} color={colors.primary} />
                        </button>
                      </div>
                      <div className="ms-2">
                        <button
                          id="myToastBtn"
                          type="button"
                          className="border-0 bg-transparent"
                          onClick={handleCopySavedSearch(search[Object.keys(search)])}
                        >
                          <FontAwesomeIcon icon={['fas', 'clipboard']} color={colors.primary} />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <RHToast showToast={showToast} setShowToast={setShowToast} />
      </div>
    </>
  );
};
