import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Text } from '../../theme';
import { ResourceContentType } from '../../components';
import colors from '../../scss/color.module.scss';
const { headerfooterbg } = colors;
import OrderProducts from './OrderProducts';
import getImageByKey from '../../utils/getImages';
import { useMobile } from '../../utils/hooks';
import './ProductCard.scss';
import { RHImage } from '../../theme/wrappers/RHImage';

export default function ProductCard({ product }) {
  const { isMobile } = useMobile();

  return (
    <div
      className={classnames(
        'mx-0',
        'mb-4',
        { 'py-4': !isMobile },
        { 'p-0': isMobile },
        'row',
        'product-card',
        { 'recommended-border': product.recommendation },
      )}
      key={`resourceCard${product.id}`}
    >
      {!isMobile && (
        <div className="col-sm-2 px-0 order-products">
          <OrderProducts id={product.id} title={product.asset.label} />
        </div>
      )}
      <a
        className="col p-0"
        href={product.asset.website ? product.asset.website : product.asset.asset_file}
        target="_blank"
        rel="noreferrer noopener"
      >
        <div
          className={classnames('px-0', {
            'd-flex flex-row justify-content-start px-0 mx-0': !isMobile,
          })}
        >
          <div
            className={classnames({ 'product-image-wrapper col-sm-4': !isMobile }, 'p-0', 'mx-0')}
          >
            <RHImage
              image={product.asset.thumbnail ? product.asset.thumbnail : getImageByKey('NoPhoto')}
              alt={product.asset.label}
              height={isMobile ? '250px' : '200px'}
            />
          </div>
          <div className={classnames({ 'ps-4 pe-0': !isMobile }, { 'px-0': isMobile })}>
            <div className="row mx-0">
              {!isMobile && (
                <div className="col-1 me-4 content-type-container">
                  <ResourceContentType content_type={product.asset.asset_type} />
                </div>
              )}
              <div className="col">
                {isMobile && (
                  <div className="my-2" key={`org${product.id}`}>
                    <Text gray italic ellipsis>
                      {product.asset.organization}
                    </Text>
                  </div>
                )}
                <div className="mb-1 product-title" key={`label${product.id}`}>
                  <Text bold large>
                    {product.recommendation && (
                      <FontAwesomeIcon
                        icon={['fas', 'badge-check']}
                        size="lg"
                        color={headerfooterbg}
                      />
                    )}
                    {product.asset.label}
                  </Text>
                </div>
                {!isMobile && (
                  <div className="mb-2" key={`org${product.id}`}>
                    <Text gray italic ellipsis>
                      {product.asset.organization}
                    </Text>
                  </div>
                )}
              </div>
            </div>
            <div
              className={classnames('product-desc', { 'px-3': isMobile })}
              key={`desc${product.id}`}
            >
              <Text dangerouslySetInnerHTML={{ __html: product.asset.description }}></Text>
            </div>
          </div>
        </div>
      </a>
      {isMobile && (
        <div className="px-3">
          <hr></hr>
          <div className="col-12 px-0 order-products">
            <OrderProducts id={product.id} title={product.asset.label} />
          </div>
        </div>
      )}
    </div>
  );
}
