import React from 'react';
import { Marker } from '@react-google-maps/api';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';

export function RHMarker({ house, houseId, setSelectedHouse, isSelectedHouse }) {
  const markerIcon = {
    path: faMapMarkerAlt.icon[4],
    fillColor: isSelectedHouse ? '#004266' : '#5CC0FF',
    fillOpacity: 0.9,
    anchor: new window.google.maps.Point(
      faMapMarkerAlt.icon[0] / 2, // width
      faMapMarkerAlt.icon[1], // height
    ),
    strokeWeight: 1,
    strokeColor: '#ffffff',
    scale: 0.075,
  };

  const location = { lat: house.address.latitude, lng: house.address.longitude };

  return (
    <div>
      {!house.address.privateAddress && house.address.latitude && house.address.longitude ? (
        <Marker
          icon={markerIcon}
          key={`${house.address.latitude}-${house.address.longitude}`}
          position={location}
          onClick={() => {
            setSelectedHouse(houseId);
          }}
        />
      ) : null}
    </div>
  );
}
