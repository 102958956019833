export const AmenitiesConstants = {
  AMEN_WIFI: 'AMEN_WIFI',
  AMEN_STORAGE: 'AMEN_STORAGE',
  AMEN_BATH_SHARED: 'AMEN_BATH_SHARED',
  AMEN_BATH_PRIVATE: 'AMEN_BATH_PRIVATE',
  AMEN_KITCHEN_COMMUNAL: 'AMEN_KITCHEN_COMMUNAL',
  AMEN_LAUNDRY: 'AMEN_LAUNDRY',
  AMEN_PARKING_ON_PAID: 'AMEN_PARKING_ON_PAID',
  AMEN_MEALS: 'AMEN_MEALS',
  AMEN_NARCAN: 'AMEN_NARCAN',
  AMEN_AIR_CONDITIONING: 'AMEN_AIR_CONDITIONING',
  AMEN_ANIMAL_PET: 'AMEN_ANIMAL_PET',
  AMEN_ANIMAL_SERVICE: 'AMEN_ANIMAL_SERVICE',
  AMEN_BABY_BATH: 'AMEN_BABY_BATH',
  AMEN_BABY_CHANGE: 'AMEN_BABY_CHANGE',
  AMEN_BABY_CHAIR: 'AMEN_BABY_CHAIR',
  AMEN_BABY_CRIB: 'AMEN_BABY_CRIB',
  AMEN_BABY_GATE: 'AMEN_BABY_GATE',
  AMEN_BABY_MONITOR: 'AMEN_BABY_MONITOR',
  AMEN_BACKYARD: 'AMEN_BACKYARD',
  AMEN_BOARD_GAMES: 'AMEN_BOARD_GAMES',
  AMEN_BOOKS: 'AMEN_BOOKS',
  AMEN_CARBON_ALARM: 'AMEN_CARBON_ALARM',
  AMEN_CHILD_BOOKS: 'AMEN_CHILD_BOOKS',
  AMEN_COMPUTERS: 'AMEN_COMPUTERS',
  AMEN_DINING: 'AMEN_DINING',
  AMEN_ELEVATOR: 'AMEN_ELEVATOR',
  AMEN_EQUIP_EXERCISE: 'AMEN_EQUIP_EXERCISE',
  AMEN_EQUIP_REC: 'AMEN_EQUIP_REC',
  AMEN_FIRE_PIT: 'AMEN_FIRE_PIT',
  AMEN_FURNISH_ROOM: 'AMEN_FURNISH_ROOM',
  AMEN_GRILL: 'AMEN_GRILL',
  AMEN_HEATING: 'AMEN_HEATING',
  AMEN_IN_ROOM_TV: 'AMEN_IN_ROOM_TV',
  AMEN_KITCHEN_PERSONAL: 'AMEN_KITCHEN_PERSONAL',
  AMEN_LAUNDRY_NEARBY: 'AMEN_LAUNDRY_NEARBY',
  AMEN_LINENS: 'AMEN_LINENS',
  AMEN_OUTDOOR_DINING: 'AMEN_OUTDOOR_DINING',
  AMEN_OUTDOOR_FURNITURE: 'AMEN_OUTDOOR_FURNITURE',
  AMEN_OVERNIGHT_GUEST: 'AMEN_OVERNIGHT_GUEST',
  AMEN_PARKING_FREE: 'AMEN_PARKING_FREE',
  AMEN_PARKING_OFF_PAID: 'AMEN_PARKING_OFF_PAID',
  AMEN_PARKING_STREET: 'AMEN_PARKING_STREET',
  AMEN_PATIO: 'AMEN_PATIO',
  AMEN_POOL: 'AMEN_POOL',
  AMEN_WATERFRONT: 'AMEN_WATERFRONT',
  AMEN_WORKSPACE: 'AMEN_WORKSPACE',
};

export const AmenitiesData = [
  {
    id: AmenitiesConstants.AMEN_WIFI,
    label: 'Wifi',
    service_id: 735,
  },
  {
    id: AmenitiesConstants.AMEN_STORAGE,
    label: 'Personal Storage',
    service_id: 641,
  },
  {
    id: AmenitiesConstants.AMEN_BATH_SHARED,
    label: 'Shared bathrooms',
    service_id: 737,
  },
  {
    id: AmenitiesConstants.AMEN_BATH_PRIVATE,
    label: 'Private Bathrooms',
    service_id: 736,
  },
  {
    id: AmenitiesConstants.AMEN_KITCHEN_COMMUNAL,
    label: 'Communal Kitchen',
    service_id: 731,
  },
  {
    id: AmenitiesConstants.AMEN_LAUNDRY,
    label: 'Laundry',
    service_id: 660,
  },
  {
    id: AmenitiesConstants.AMEN_PARKING_ON_PAID,
    label: 'Paid parking on premises',
    service_id: 748,
  },
  {
    id: AmenitiesConstants.AMEN_MEALS,
    label: 'Meals Provided',
    service_id: 749,
  },
  {
    id: AmenitiesConstants.AMEN_NARCAN,
    label: 'Naloxone/Narcan',
    service_id: 742,
  },
  {
    id: AmenitiesConstants.AMEN_AIR_CONDITIONING,
    label: 'Air Conditioning',
    service_id: 729,
  },
  {
    id: AmenitiesConstants.AMEN_ANIMAL_PET,
    label: 'Pets allowed',
    service_id: 806,
  },
  {
    id: AmenitiesConstants.AMEN_ANIMAL_SERVICE,
    label: 'Service animals',
    service_id: 807,
  },
  {
    id: AmenitiesConstants.AMEN_BABY_BATH,
    label: 'Baby bath',
    service_id: 753,
  },
  {
    id: AmenitiesConstants.AMEN_BABY_CHAIR,
    label: 'High chair',
    service_id: 740,
  },
  {
    id: AmenitiesConstants.AMEN_BABY_CHANGE,
    label: 'Changing table',
    service_id: 738,
  },
  {
    id: AmenitiesConstants.AMEN_BABY_CRIB,
    label: 'Crib',
    service_id: 739,
  },
  {
    id: AmenitiesConstants.AMEN_BABY_GATE,
    label: 'Baby safety gates',
    service_id: 755,
  },
  {
    id: AmenitiesConstants.AMEN_BABY_MONITOR,
    label: 'Baby monitor',
    service_id: 754,
  },
  {
    id: AmenitiesConstants.AMEN_BACKYARD,
    label: 'Backyard',
    service_id: 763,
  },
  {
    id: AmenitiesConstants.AMEN_BOARD_GAMES,
    label: 'Board games',
    service_id: 761,
  },
  {
    id: AmenitiesConstants.AMEN_BOOKS,
    label: 'Books and reading material',
    service_id: 752,
  },
  {
    id: AmenitiesConstants.AMEN_CARBON_ALARM,
    label: 'Carbon monoxide alarm',
    service_id: 741,
  },
  {
    id: AmenitiesConstants.AMEN_CHILD_BOOKS,
    label: 'Children’s books and toys',
    service_id: 756,
  },
  {
    id: AmenitiesConstants.AMEN_COMPUTERS,
    label: 'Computers Available',
    service_id: 730,
  },
  {
    id: AmenitiesConstants.AMEN_DINING,
    label: 'Dining Area',
    service_id: 732,
  },
  {
    id: AmenitiesConstants.AMEN_ELEVATOR,
    label: 'Elevator',
    service_id: 744,
  },
  {
    id: AmenitiesConstants.AMEN_EQUIP_EXERCISE,
    label: 'Exercise equipment',
    service_id: 759,
  },
  {
    id: AmenitiesConstants.AMEN_EQUIP_REC,
    label: 'Recreational Equipment',
    service_id: 760,
  },
  {
    id: AmenitiesConstants.AMEN_FIRE_PIT,
    label: 'Fire Pit',
    service_id: 765,
  },
  {
    id: AmenitiesConstants.AMEN_FURNISH_ROOM,
    label: 'Furnished room',
    service_id: 805,
  },
  {
    id: AmenitiesConstants.AMEN_GRILL,
    label: 'BBQ grill',
    service_id: 764,
  },
  {
    id: AmenitiesConstants.AMEN_HEATING,
    label: 'Heating',
    service_id: 733,
  },
  {
    id: AmenitiesConstants.AMEN_IN_ROOM_TV,
    label: 'In-room TV',
    service_id: 734,
  },
  {
    id: AmenitiesConstants.AMEN_KITCHEN_PERSONAL,
    label: 'Personal Kitchen',
    service_id: 751,
  },
  {
    id: AmenitiesConstants.AMEN_LAUNDRY_NEARBY,
    label: 'Laundromat nearby',
    service_id: 743,
  },
  {
    id: AmenitiesConstants.AMEN_LINENS,
    label: 'Bed Linens (Sheets, Covers, Pillows)',
    service_id: 750,
  },
  {
    id: AmenitiesConstants.AMEN_OUTDOOR_DINING,
    label: 'Outdoor dining area',
    service_id: 766,
  },
  {
    id: AmenitiesConstants.AMEN_OUTDOOR_FURNITURE,
    label: 'Outdoor furniture',
    service_id: 767,
  },
  {
    id: AmenitiesConstants.AMEN_OVERNIGHT_GUEST,
    label: 'Allows overnight guests',
    service_id: 808,
  },
  {
    id: AmenitiesConstants.AMEN_PARKING_FREE,
    label: 'Free parking on premises',
    service_id: 745,
  },
  {
    id: AmenitiesConstants.AMEN_PARKING_OFF_PAID,
    label: 'Paid parking off premises',
    service_id: 747,
  },
  {
    id: AmenitiesConstants.AMEN_PARKING_STREET,
    label: 'Free street parking',
    service_id: 746,
  },
  {
    id: AmenitiesConstants.AMEN_PATIO,
    label: 'Patio or balcony',
    service_id: 768,
  },
  {
    id: AmenitiesConstants.AMEN_POOL,
    label: 'Pool',
    service_id: 758,
  },
  {
    id: AmenitiesConstants.AMEN_WATERFRONT,
    label: 'Waterfront',
    service_id: 762,
  },
  {
    id: AmenitiesConstants.AMEN_WORKSPACE,
    label: 'Dedicated workspace',
    service_id: 757,
  },
];
