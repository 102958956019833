import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, RHLink, Text } from '../../../theme';
import Strings from '../../../AppData/Strings/HousesStrings';
import { AmenitiesSearchFilters } from './AmenitiesSearchFilters';
import { FinancesSearchFilters } from './FinancesSearchFilters';
import { HousingOptionsSearchFilters } from './HousingOptionsSearchFilters';
import { LocationSearchFilters } from './LocationSearchFilters';
import { ResidentAcceptanceSearchFilters } from './ResidentAcceptanceSearchFilters';
import { ServicesSearchFilters } from './ServicesSearchFilters';
import { TransportationsSearchFilters } from './TransportationsSearchFilters';
import './SearchFilters.scss';
import { CloseButton } from '../../../components';
import { useSearch } from '../../../utils/useSearch';
import { useGSModal } from '../../../utils/hooks';
import { useDispatch } from 'react-redux';
import { setSearchState } from '../../../features/searchSlice';
import { ErrorText } from '../../../theme/wrappers/ErrorText';
import ReactGA from 'react-ga4';

export function SearchFilters({ refSearch, modal, searchStateCopy, setSearchStateCopy }) {
  const { onSubmit } = useSearch();
  const { onGSModalOpen } = useGSModal();
  const dispatch = useDispatch();
  const [invalidSearch, setInvalidSearch] = useState(false);
  const handleSearch = () => {
    if (searchStateCopy.radius && searchStateCopy.cityStateZip && !searchStateCopy.latitude) {
      setInvalidSearch(true);
    } else {
      setInvalidSearch(false);
      onSubmit(searchStateCopy);
      dispatch(setSearchState(searchStateCopy));
      modal.hide();
    }
  };

  return (
    <div
      className="modal fade search-filters-container"
      ref={refSearch}
      tabIndex="-1"
      aria-labelledby="searchRecoveryHousing"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header border-0 mx-4">
            <Text extra_large className="w-100 text-center">
              <FontAwesomeIcon icon={['fal', 'sliders-h']} />
              {Strings.Houses.SEARCH_FILTERS.text}
            </Text>
            <CloseButton onClick={() => modal.hide()} />
          </div>
          <div className="modal-body mx-4">
            <div className="accordion" id="searchFiltersAccordion">
              <LocationSearchFilters
                searchStateCopy={searchStateCopy}
                setSearchStateCopy={setSearchStateCopy}
                invalidSearch={invalidSearch}
                setInvalidSearch={setInvalidSearch}
              />
              <ResidentAcceptanceSearchFilters
                searchStateCopy={searchStateCopy}
                setSearchStateCopy={setSearchStateCopy}
              />
              <FinancesSearchFilters
                searchStateCopy={searchStateCopy}
                setSearchStateCopy={setSearchStateCopy}
              />
              <HousingOptionsSearchFilters
                searchStateCopy={searchStateCopy}
                setSearchStateCopy={setSearchStateCopy}
              />
              <AmenitiesSearchFilters
                searchStateCopy={searchStateCopy}
                setSearchStateCopy={setSearchStateCopy}
              />
              <TransportationsSearchFilters
                searchStateCopy={searchStateCopy}
                setSearchStateCopy={setSearchStateCopy}
              />
              <ServicesSearchFilters
                searchStateCopy={searchStateCopy}
                setSearchStateCopy={setSearchStateCopy}
              />
            </div>
          </div>
          <div className="modal-footer border-0 mb-4 mx-4 justify-content-between">
            <Text className="text-left">
              {Strings.Houses.NEED_HELP.text}&nbsp;&nbsp;
              <RHLink
                to={'#'}
                onClick={(e) => {
                  e.preventDefault();
                  modal.hide();
                  onGSModalOpen();
                  ReactGA.event({
                    category: 'guidedSearch',
                    action: 'guidedSearch',
                    label: 'searchFilterGuidedSearch',
                  });
                }}
              >
                {Strings.Houses.USE_OUR_GUIDED_SEARCH.text}
              </RHLink>
            </Text>
            <div>
              <Button
                type="button"
                primary
                width="229px"
                height="36px"
                large
                onClick={handleSearch}
              >
                {Strings.Houses.RECOVERY_HOUSES.text}
              </Button>
            </div>
            {invalidSearch && (
              <ErrorText bgWhite="showBackground" className="p-2">
                <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                <span>
                  If using a distance radius within Location, you must also enter a city or zip code
                  and choose from the autocomplete dropdown options.
                </span>
              </ErrorText>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
