import React from 'react';
import './FontAwesome';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { NavBar } from './components';
import { Footer } from './components';
import MainPage from './pages/mainpage/MainPage';
import Resources from './pages/resources/Resources';
import Products from './pages/products/Products';
import AboutUs from './pages/AboutUs/AboutUs';
import Glossary from './pages/Glossary/Glossary';
import Houses from './pages/houses/Houses';
import HouseDetail from './pages/HouseDetail/HouseDetail';
import RequestListHouse from './pages/RequestListHouse';
import ContactUs from './pages/ContactUs';
import ReactGA from 'react-ga4';
import { embedScripts } from './utils/embedTrackingScript';

ReactGA.initialize(process.env.REACT_APP_GA_ID);
embedScripts();

function App() {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route path="/houses">
          <Houses />
        </Route>
        <Route path="/resources">
          <Resources />
        </Route>
        <Route path="/products">
          <Products />
        </Route>
        <Route path="/about">
          <AboutUs />
        </Route>
        <Route path="/glossary">
          <Glossary />
        </Route>
        <Route path="/listHouse">
          <RequestListHouse />
        </Route>
        <Route path="/contactUs">
          <ContactUs />
        </Route>
        <Route path="/houseDetail">
          <HouseDetail />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
