import React from 'react';
import axios from 'axios';
import Entries from '../Glossary/Entries';
import { HeroImage, RHLink, Text } from '../../theme';
import { useQuery } from 'react-query';
import { Search } from '../../components';
import { useState } from 'react';
import { useMobile } from '../../utils/hooks/useMobile';
import GoToTop from '../../utils/goToTop';
import { tabTitle } from '../../utils/tabTitle';

const getGlossaryInfo = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, { searchText }] = queryKey;

  let searchTextQuery = '';
  if (searchText) {
    searchTextQuery = `&searchText=${searchText}`;
  }

  const baseURL = `${process.env.REACT_APP_API_ROOT}/api/glossary/glossarys/`;
  const { data } = await axios.get(`${baseURL}?${searchTextQuery}`);

  return data;
};

export default function Glossary() {
  const { isMobile } = useMobile();
  const [searchText, setSearchText] = useState(null);
  tabTitle('Glossary - Find Recovery Housing Now');

  const {
    isSuccess: isGlossarySuccess,
    error: glossaryError,
    data: glossaryData,
  } = useQuery(['glossary/glossarys/', { searchText }], getGlossaryInfo);

  if (glossaryError) {
    return 'An error occurred while fetching the glossary terms: ' + glossaryError.message;
  }

  const onSubmitSearchText = (data) => {
    setSearchText(data.searchText);
  };

  return (
    <>
      <HeroImage
        height={isMobile ? '200px' : '160px'}
        className="d-flex flex-column justify-content-center"
        image="glossaryHero"
      >
        <span className="mt-4 text-center mb-3 ">
          <Text XX_large white shadow>
            Glossary
          </Text>
        </span>
        <div className="col d-flex justify-content-center">
          <Text shadow large white className="col col-md-8 text-center mb-5">
            Language for recovery housing is very important. In this section, there are definitions
            to the terms that are throughout the site and can be used as&nbsp;a&nbsp;guide.
          </Text>
        </div>
      </HeroImage>
      <Text className="text-center bg-banner py-1">
        Still need help?&nbsp;&nbsp;
        <RHLink to="/contactUs/">Contact us</RHLink>.
      </Text>
      <div className="container-xxl">
        <div className="mx-5 mt-4">
          <Search
            placeholder="Search terms"
            handleSubmitSearchText={onSubmitSearchText}
            setSearchText={setSearchText}
          />
        </div>
        <div className="m-5">
          {isGlossarySuccess
            ? glossaryData[0] == null
              ? 'No terms found matching your search.'
              : glossaryData.map((obj, index) => <Entries key={index} obj={obj} />)
            : 'Loading'}
        </div>
      </div>
      <GoToTop />
    </>
  );
}
