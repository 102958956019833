import React from 'react';
import { Text } from '../../theme';
import { TextWithLink } from './TextWithLink';

export function SubHeading({ text, path, linkText }) {
  return (
    <div className="bg-banner w-100 text-center py-2">
      <Text>
        <TextWithLink text={text} path={path} linkText={linkText} />
      </Text>
    </div>
  );
}
