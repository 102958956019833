import React from 'react';
import { Text } from '../../theme';
import getImageByKey from '../../utils/getImages';
import { useMobile } from '../../utils/hooks';
import classnames from 'classnames';

export default function LinkSec({ index, obj: { label, image_name, text, LINK } }) {
  const { isMobile } = useMobile();

  return (
    <div
      className={classnames(
        'd-flex flex-column',
        'align-items-center',
        'text-center',
        { 'py-5 m-0 p-0': isMobile },
        { 'p-3': !isMobile },
      )}
    >
      <div
        className={classnames(
          { 'd-flex justify-content-start': !isMobile },
          { 'd-flex flex-row-reverse': index % 2 == 0 && !isMobile },
          'align-items-center',
        )}
      >
        <div>
          <Text
            XX_large
            bold
            className={classnames(
              { 'ms-3': index % 2 != 0 && !isMobile },
              { 'text-start ps-3': !isMobile },
              'pb-2',
            )}
          >
            {label}
          </Text>
          <Text
            className={classnames(
              { 'px-3 ms-3': index % 2 != 0 && !isMobile },
              { 'pe-3 ms-3': index % 2 == 0 && !isMobile },
              'text-start',
            )}
            dangerouslySetInnerHTML={{ __html: text }}
          ></Text>
        </div>
        <a href={LINK.route} target="_blank" rel="noopener noreferrer">
          <img src={getImageByKey(image_name)} className="linkSecImage my-3" alt={label} />
        </a>
      </div>
    </div>
  );
}
