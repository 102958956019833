import styled from 'styled-components';
import colors from '../../scss/color.module.scss';
const { textwhite, textblack, primary, white } = colors;
import { handleFontsize } from './Text';

const Button = styled.button.attrs()`
  background-color: ${(props) => (props.primary ? primary : white)};
  border: ${(props) => (props.primary ? 'none' : 'solid')};
  border-radius: ${(props) => props.radius || '2px'};
  color: ${(props) => (props.primary ? textwhite : textblack)};
  font-size: ${(props) => handleFontsize(props)};
  type: ${(props) => (props.submit ? 'submit' : 'button')};
  width: ${(props) => props.width || '150px'};
  height: ${(props) => props.height || '60px'};
`;

export { Button };
