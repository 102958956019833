import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'bootstrap';
import { Dropdown, Checkbox } from '../../components';
import { AverageRentDropdown } from './AverageRentDropdown';
import { SearchFilters } from './searchFilters/SearchFilters';
import Strings from '../../AppData/Strings/HousesStrings';
import colors from '../../scss/color.module.scss';
const { textblack } = colors;
import './BasicFilter.scss';
import { createOptions } from '../../utils/createDropdownOptions';
import { PaymentMethodsData } from '../../AppData/GetHelpConstants/Payment';
import { useSearch } from '../../utils/useSearch';
import { useSelector, useDispatch } from 'react-redux';
import { initialSearchState, resetSearchState, setSearchValue } from '../../features/searchSlice';
import store from '../../store';
import { cloneDeep } from 'lodash';
import { SaveSearchButton } from '../../components/shared/SaveSearchButton';
import { SaveSearchPopover } from '../../components/shared/SaveSearchPopover';
import { SaveSearch } from './SaveSearch';
import { getDomainValue } from '../../utils/hooks';

export const ViewOptions = [
  {
    value: '0',
    label: (
      <>
        <FontAwesomeIcon icon={['fal', 'list']} />
        List View
      </>
    ),
  },
  {
    value: '1',
    label: (
      <>
        <FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
        Map View
      </>
    ),
  },
];

export function BasicFilters({ setSelectedViewType }) {
  const [modal, setModal] = useState(null);
  const [toggleDebounceTimer, setToggleDebounceTimer] = useState(null);
  const [basicFiltersOnChangeState, setBasicFiltersOnChangeState] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const searchFiltersModal = useRef();
  const { onSubmit } = useSearch();
  const characteristics = useSelector((state) => state.search.characteristics);
  const paymentTypes = useSelector((state) => state.search.paymentTypes);
  const facilityAvailable = useSelector((state) => state.search.facilityAvailable);
  const NARRCertified = useSelector((state) => state.search.accreditations);
  const searchName = useSelector((state) => state.search.name);
  const dispatch = useDispatch();
  const [searchStateCopyDesktop, setSearchStateCopyDesktop] = useState(
    cloneDeep(store.getState().search),
  );

  useEffect(() => {
    setModal(new Modal(searchFiltersModal.current));
  }, []);

  useEffect(() => {
    if (!initialLoad) {
      onSubmit(store.getState().search);
    } else {
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicFiltersOnChangeState]);

  const toggleOnChangeState = () => {
    if (toggleDebounceTimer) {
      clearTimeout(toggleDebounceTimer);
    }

    const toggleTimer = setTimeout(
      () => setBasicFiltersOnChangeState(!basicFiltersOnChangeState),
      400,
    );

    setToggleDebounceTimer(toggleTimer);
  };

  const openMoreFilters = () => {
    setSearchStateCopyDesktop(cloneDeep(store.getState().search), modal.show());
  };

  const togglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  return (
    <div className="col">
      <div className="row">
        <div className="mx-2 col d-flex justify-content-start">
          <button className="more-filters-button" type="button" onClick={() => openMoreFilters()}>
            {Strings.Houses.MORE_FILTERS.text}
          </button>
          <button
            className="clear-filters-button ms-2"
            onClick={() => {
              dispatch(resetSearchState());
              onSubmit(initialSearchState);
            }}
          >
            Clear all
          </button>
        </div>
        <div className="col-md-4 col-xl-2 d-flex justify-content-end">
          <div className="border-end pe-3 align-self-center">
            <SaveSearchPopover
              content={
                <SaveSearch title={'Saved Searches'} favoriteHouses={getDomainValue('House')} />
              }
              isPopoverOpen={isPopoverOpen}
              setIsPopoverOpen={setIsPopoverOpen}
            >
              <SaveSearchButton onClick={togglePopover} />
            </SaveSearchPopover>
          </div>
          <div className="view-options ps-2">
            <Dropdown
              instanceId="views"
              defaultValue={ViewOptions[0]}
              options={ViewOptions}
              updateSelection={setSelectedViewType}
            />
          </div>
        </div>
        <SearchFilters
          refSearch={searchFiltersModal}
          modal={modal}
          searchStateCopy={searchStateCopyDesktop}
          setSearchStateCopy={setSearchStateCopyDesktop}
        />
      </div>
      <div className="row mx-2 mt-3">
        <div className="col d-flex justify-content-between">
          <div>
            <Checkbox
              className="only-show-avail"
              label={Strings.Houses.ONLY_SHOW_AVAILABLE_HOUSES.text}
              isDefaultChecked={facilityAvailable || false}
              onChange={(e) => {
                dispatch(setSearchValue({ value: e.target.checked, field: 'facilityAvailable' }));
                toggleOnChangeState();
              }}
            />
          </div>
          <div>
            <Checkbox
              className="only-show-avail"
              label={Strings.Houses.NARR_CERTIFIED.text}
              isDefaultChecked={NARRCertified.NARR || false}
              onChange={(e) => {
                const accreditationsValue = e.target.checked ? { NARR: true } : { NARR: false };
                dispatch(setSearchValue({ value: accreditationsValue, field: 'accreditations' }));
                toggleOnChangeState();
              }}
            />
          </div>
          <div className="search-input">
            <div className="input-group housing-options-search">
              <span className="input-group-text bg-white border-end-0 search-icon">
                <FontAwesomeIcon icon={['far', 'search']} />
              </span>
              <input
                type="text"
                className="form-control border-start-0 px-0  mt-md-2 mt-lg-0"
                placeholder={Strings.Houses.SEARCH_SPECIFIC_HOME_NAME.text}
                aria-label={Strings.Houses.SEARCH_SPECIFIC_HOME_NAME.text}
                aria-describedby="basic-addon2"
                value={searchName || ''}
                onChange={(e) => {
                  dispatch(setSearchValue({ value: e.target.value, field: 'name' }));
                  toggleOnChangeState();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-2">
        <div className="col d-flex justify-content-start">
          <div className="resident-characteristics-dropdown mt-md-2 mt-lg-0">
            <Dropdown
              isClearable={true}
              isMulti={true}
              options={createOptions('characteristics')}
              instanceId="residentMod"
              placeholder={Strings.Houses.RESIDENT_MOD.text}
              menuWidth="135%"
              controlShouldRenderValue={false}
              blurInputOnSelect={false}
              wrapPlaceholder={true}
              placeHolderStyle={{
                color: textblack,
              }}
              showBadge={true}
              value={characteristics || ''}
              updateSelection={(value) => {
                dispatch(setSearchValue({ value, field: 'characteristics' }));
                toggleOnChangeState();
              }}
            />
          </div>
        </div>
        <div className="col d-flex justify-content-center">
          <div className="payment-types-dropdown mt-md-2 mt-lg-0">
            <Dropdown
              isClearable={true}
              options={PaymentMethodsData}
              instanceId="paymentTypes"
              placeholder={Strings.Houses.PAYMENT_TYPES.text}
              menuWidth="122%"
              placeHolderStyle={{
                color: textblack,
              }}
              wrapPlaceholder={true}
              value={PaymentMethodsData.find((element) => element.id === paymentTypes) || ''}
              updateSelection={(value) => {
                dispatch(setSearchValue({ value: value ? value.id : '', field: 'paymentTypes' }));
                toggleOnChangeState();
              }}
            />
          </div>
        </div>
        <div className="col d-flex justify-content-end">
          <div className="payment-types-dropdown mt-md-2 mt-lg-0">
            <AverageRentDropdown
              setBasicFiltersOnChangeState={setBasicFiltersOnChangeState}
              basicFiltersOnChangeState={basicFiltersOnChangeState}
              toggleOnChangeState={toggleOnChangeState}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
