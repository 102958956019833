import React from 'react';
import getImageByKey from '../../utils/getImages';
import Strings from '../../AppData/Strings/AboutUsStrings.json';
import { Text } from '../../theme';
import MidSecItem from './MidSecItem';

export default function AboutUsMidSec() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 col-sm-12 ps-5 pe-0">
          <img
            src={getImageByKey('House')}
            alt="Recovery Housing Web Site Features"
            className="img-fluid h-100 d-none d-md-block"
          />
        </div>
        <div className="col-md-6 col-sm-12 text-center">
          <div className="row py-3 bg-lightblue h-100">
            <Text XXX_large bold className="pb-2">
              {Strings.CAN_DO.Label}
            </Text>
            {Strings.CAN_DO.SECTIONS.map((obj, index) => (
              <MidSecItem key={index} obj={obj} index={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
