import React from 'react';
import { Checkbox, Dropdown } from '../../../components';
import { Text } from '../../../theme';
import { LanguagesData } from '../../../AppData/GetHelpConstants/Languages';
import { CriminalBackgroundData } from '../../../AppData/GetHelpConstants/CriminalBackground';
import { createOptions } from '../../../utils/createDropdownOptions';
import Strings from '../../../AppData/Strings/HousesStrings';
import './SearchFilters.scss';
import { AccessibilityData } from '../../../AppData/GetHelpConstants/Accessibility';

export function ResidentAcceptanceSearchFilters({ searchStateCopy, setSearchStateCopy }) {
  return (
    <div className="accordion-item search-border">
      <div className="accordion-header" id="headingFour">
        <button
          type="button"
          className="accordion-button collapsed p-0 search-format"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFour"
          aria-expanded="false"
          aria-controls="collapseFour"
        >
          <Text bold large className="text-left mb-2 col">
            {Strings.Houses.RESIDENT_ACCEPTANCE.text}
          </Text>
        </button>
      </div>
      <div
        id="collapseFour"
        className="accordion-collapse collapse"
        aria-labelledby="headingFour"
        data-bs-parent="#searchFiltersAccordion"
      >
        <div className="row accordion-body">
          <div className="col-12 col-lg-6 pe-2">
            <div className="row row-cols-2 my-3">
              <label className="label-text">{Strings.Houses.GENDER.text}</label>
              <Dropdown
                instanceId="genderSearchFilter"
                isClearable={true}
                options={createOptions('gender')}
                placeholder={Strings.Houses.SELECT.text}
                border={true}
                value={
                  createOptions('gender').find(
                    (element) => element.id === searchStateCopy.gender,
                  ) || ''
                }
                updateSelection={(value) =>
                  setSearchStateCopy({ ...searchStateCopy, gender: value ? value.id : '' })
                }
              />
            </div>
            <div className="row row-cols-2 my-3">
              <label className="label-text">{Strings.Houses.RESIDENT_MOD.text}</label>
              <Dropdown
                isClearable={true}
                isMulti={true}
                options={createOptions('characteristics')}
                instanceId="residentModSearchFilters"
                placeholder={Strings.Houses.SELECT.text}
                border={true}
                value={searchStateCopy.characteristics || ''}
                updateSelection={(value) =>
                  setSearchStateCopy({ ...searchStateCopy, characteristics: value })
                }
              />
            </div>
            <div className="row row-cols-2 my-3">
              <label className="label-text">{Strings.Houses.LANGUAGES_SPOKEN.text}</label>
              <Dropdown
                instanceId="languagesSearchFilter"
                isClearable={true}
                isMulti={false}
                options={LanguagesData}
                placeholder={Strings.Houses.SELECT.text}
                border={true}
                value={
                  LanguagesData.find((element) => element.id === searchStateCopy.language) || ''
                }
                updateSelection={(value) =>
                  setSearchStateCopy({ ...searchStateCopy, language: value ? value.id : '' })
                }
              />
            </div>
          </div>
          <div className="col-12 col-lg-6 ps-3">
            <div className="row row-cols-2 my-3">
              <label className="label-text">
                {Strings.Houses.CRIMINAL_BACKGROUNDS_ACCEPTED.text}
              </label>
              <Dropdown
                instanceId="backgroundSearchFilter"
                isClearable={true}
                isMulti={true}
                options={CriminalBackgroundData.filter(
                  (element) => element.id !== 'NON_APPLICABLE',
                )}
                placeholder={Strings.Houses.SELECT.text}
                border={true}
                value={searchStateCopy.legalExceptions}
                updateSelection={(value) =>
                  setSearchStateCopy({ ...searchStateCopy, legalExceptions: value })
                }
              />
            </div>
            {AccessibilityData.map((accessibility, index) => {
              if ('service_id' in accessibility) {
                return (
                  <div className="my-3" key={`${accessibility.id}-${index}`}>
                    <Checkbox
                      label={accessibility.label}
                      onChange={(e) => {
                        const tempSearchData = { ...searchStateCopy.accessibility };
                        tempSearchData[accessibility.id] = e.target.checked;
                        setSearchStateCopy({ ...searchStateCopy, accessibility: tempSearchData });
                      }}
                      isDefaultChecked={searchStateCopy.accessibility[accessibility.id] || false}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      <hr></hr>
    </div>
  );
}
