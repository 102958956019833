import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { convertDistance, getDistance } from 'geolib';

const useGeolocation = (house, from = null) => {
  const [geoLocation, setGeoLocation] = useState({ lat: null, lng: null });
  const [distance, setDistance] = useState(null);
  const [loading, setLoading] = useState(false);

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  Geocode.setLanguage('en');
  Geocode.setRegion('us');
  Geocode.setLocationType('ROOFTOP');
  // Geocode.enableDebug();

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (!house.hasOwnProperty('geo_location')) {
      setLoading(true);
      const formattedAddress = `${house.address1} ${house.address2},${house.city}, ${house.state} ${house.zipcode}`;
      Geocode.fromAddress(formattedAddress).then(
        (response) => {
          const loc = response.results[0].geometry.location;
          setGeoLocation(loc);
          house.geo_location = loc;
          let dist = from != null ? getDistance(loc, from) : null; //in meters
          dist = Math.round(convertDistance(dist, 'mi'));
          setDistance(dist);
          house.distance = dist;
          setLoading(false);
        },
        (error) => {
          console.error('Geocode error', error);
        },
      );
    } else {
      setGeoLocation(house.geo_location);
      setDistance(house.distance);
    }
  }, [house, from, geoLocation, distance]);

  return {
    loading,
    geoLocation,
    distance,
  };
};

export { useGeolocation };
