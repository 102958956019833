import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Text } from '../../theme';
import { MedicationsData } from '../../AppData/GetHelpConstants/Medications';
import { MedTreatmentLabel } from '../../AppData/GetHelpConstants/MedTreatmentDetails';
import { SmokingConstants, SmokingData } from '../../AppData/GetHelpConstants/Smoking';
import ItemList from './ItemList';
import MoreInfo from './../../AppData/Strings/MoreInfo.json';
import MoreInfoModal from '../../components/shared/MoreInfoModal';
import MoreInfoLink from './MoreInfoLink';
import { PolicyDocTagsData } from '../../AppData/GetHelpConstants/PolicyDocTags';
import colors from './../../scss/color.module.scss';
const { inputlines } = colors;

export default function RulesAndPolicies({ houseInfo }) {
  let houseSmokingStatus;
  if (houseInfo.rulesPolicies.smokingPolicy) {
    const findHouseSmokingStatus = (element) => element.id == houseInfo.rulesPolicies.smokingPolicy;
    houseSmokingStatus = SmokingData.find(findHouseSmokingStatus);
  } else {
    houseSmokingStatus = SmokingData.find(
      (element) => element.id == SmokingConstants.PT_TOBACCO_NOT_ALLOWED,
    );
  }

  const noMedTreatment =
    houseInfo.rulesPolicies.medAssistTreatments[0] === 'NONE' ||
    houseInfo.rulesPolicies.medAssistTreatments === null;

  function CreatePolicyDocLink({ policyDoc }) {
    let formattedTagList = '';
    if (policyDoc.tags) {
      // eslint-disable-next-line max-len
      const tagList = policyDoc.tags
        .split(',')
        .map((tag) => PolicyDocTagsData.find((element) => element.id == tag).label);
      formattedTagList = tagList.join(', ');
    }

    return (
      <Text textlink large className="mb-2 mt-3">
        <FontAwesomeIcon icon={['fa', 'external-link-alt']} />
        <a className="link-primary" href={policyDoc.url} target="_blank" rel="noreferrer">
          {`View our ${formattedTagList} policy document`}
        </a>
      </Text>
    );
  }

  return (
    <>
      <div className="row mt-3">
        <Text XX_large bold className="mb-4">
          House Rules & Policies
        </Text>
        {houseInfo.rulesPolicies.housePolicyDocuments && (
          <>
            <div className="col">
              <Text extra_large bold className="mb-4" style={{ display: 'inline' }}>
                Policies and Procedures
              </Text>
              <MoreInfoLink
                icon="question-circle"
                target={MoreInfo.HOUSE_DETAILS.policiesAndProcedures.id}
                text="What is this?"
              />
            </div>
            <div>
              <MoreInfoModal data={MoreInfo.HOUSE_DETAILS.policiesAndProcedures} />
            </div>
            {houseInfo.rulesPolicies.housePolicyDocuments.map((policyDoc, index) => (
              <CreatePolicyDocLink key={`policyDocLink-${index}`} policyDoc={policyDoc} />
            ))}
          </>
        )}
        <Text extra_large bold className="my-3">
          Tobacco Use
        </Text>
        <div className="col mb-4">
          {houseSmokingStatus && (
            <Text
              large
              className={
                houseSmokingStatus.id === SmokingConstants.PT_TOBACCO_NOT_ALLOWED &&
                'text-decoration-line-through'
              }
            >
              <FontAwesomeIcon
                icon={['fal', houseSmokingStatus.icon]}
                color={inputlines}
                size="lg"
              />
              {houseSmokingStatus.label}
            </Text>
          )}
        </div>
        <>
          <Text extra_large bold className="mb-3">
            Medication Opioid Use Disorder Treatment Details
          </Text>
          <div className="col mb-3">
            <Text large className={noMedTreatment && 'text-decoration-line-through'}>
              <FontAwesomeIcon
                icon={noMedTreatment ? ['fal', 'times'] : ['fal', 'check']}
                color={inputlines}
                size="lg"
              />
              {MedTreatmentLabel.label}
            </Text>
          </div>
        </>
        {houseInfo.rulesPolicies.acceptedFormsMedAssist.length > 0 && (
          <>
            <Text large bold className="mb-3">
              Accepted Forms of Medication for Opioid Use Disorder
            </Text>
            {houseInfo.rulesPolicies.acceptedFormsMedAssist.map((med, index) => (
              <ItemList
                key={index}
                item={med}
                list={MedicationsData}
                bullet={true}
                singleCol={true}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
}
