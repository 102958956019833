import React from 'react';
import Strings from '../../../AppData/Strings/GuidedSearch.json';
import { Text } from '../../../theme';
import Paragraphs from '../../shared/Paragraphs';
import { LinkButtonWithIcon } from '../../shared/LinkButtonWithIcon';

export function GSWelcome({ onClick }) {
  return (
    <div className="text-center text-white">
      <Text XXX_large white className="pb-4">
        {Strings.PAGES[0].header}
      </Text>
      <Text white large className="pb-4">
        {Strings.PAGES[0].PARAGRAPHS.map((obj, index) => (
          <Paragraphs key={index} text={obj.text} />
        ))}
      </Text>
      <LinkButtonWithIcon
        link=""
        onClick={onClick}
        label={Strings.PAGES[0].label}
        icon={['far', 'angle-double-right']}
        width="200px"
        radius="2px"
        height="45px"
        lineHeight="35px"
        iconPosition="right"
      />
    </div>
  );
}
