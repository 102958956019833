import React from 'react';
import Certifications from '../../components/shared/Certification';
import colors from '../../scss/color.module.scss';
const { textdisabled } = colors;
import { Text } from '../../theme';
import Gender from './Gender';
import { LinkButtonWithIcon } from '../../components';
import 'react-multi-carousel/lib/styles.css';
import HDCarousel from './HDCarousel';
import { FavoriteButton } from '../../components';
import { StatusIndicator } from '../../components';
import { RHImage } from '../../theme/wrappers/RHImage';
import { DistanceLabel } from '../../components/shared/distanceLabel';
import { useMobile } from '../../utils/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function HeaderSection({ id, houseInfo }) {
  const { isTablet } = useMobile();

  return (
    <div>
      <div className="row">
        <div className="col col-6">
          <Text XX_large bold>
            {houseInfo.name}
          </Text>
        </div>
        <div className="col col-6 d-flex justify-content-end">
          <StatusIndicator
            status={houseInfo.status}
            updatedAt={houseInfo.updatedAt}
            type="inline"
          />
        </div>
      </div>
      <div className="row d-flex align-items-center  mt-3">
        <div className="col col-5">
          <Text large gray>
            {houseInfo.address.city && houseInfo.address.state && (
              <DistanceLabel house={houseInfo} />
            )}
          </Text>
        </div>
        <div className={`col col-7 d-flex align-items-center justify-content-end`}>
          {houseInfo.genderType && (
            <div className="pe-4 flex-fill text-end">
              <Text darkBlue bold large>
                <Gender genderServed={houseInfo.genderType} iconSize="lg" />
              </Text>
            </div>
          )}
          <div className="ms-3">
            {isTablet ? (
              <button
                className="circleButton pt-0"
                onClick={(e) => {
                  e.preventDefault();
                  window.print();
                }}
              >
                <FontAwesomeIcon icon={['fal', 'print']} color={textdisabled} size="lg" />
              </button>
            ) : (
              <LinkButtonWithIcon
                link=""
                onClick={(e) => {
                  e.preventDefault();
                  window.print();
                }}
                label={isTablet ? '' : 'Print page'}
                boxShadow="1px 1px 8px 0 rgba(0,0,0,0.3) !important"
                radius="50px"
                iconPosition="left"
                icon={['fal', 'print']}
                iconColor={colors.textdisabled}
                iconSize="lg"
              />
            )}
          </div>
          <div className={`ms-3 ${isTablet && 'circleButton'}`}>
            <FavoriteButton
              domain={'House'}
              favoriteKey={id}
              defaultValue={true}
              size={'lg'}
              backgroundColor={'mediumgray'}
              style={isTablet ? 'default' : 'button'}
              message={!isTablet && 'Add favorite'}
            />
          </div>
        </div>
      </div>
      <div className="row d-flex align-items-center mt-2">
        <div className="col">
          {houseInfo.certifications.certs.length > 0 && (
            <>
              <Text darkBlue large bold style={{ display: 'inline' }}>
                <Certifications houseInfo={houseInfo} />
              </Text>
            </>
          )}
          {houseInfo.certifications.compliesLicensing && (
            <Text className="pt-1" gray>
              *This house complies with state and/or local permit and licensing requirements.
            </Text>
          )}
        </div>
        <div className="col d-flex justify-content-end">
          <LinkButtonWithIcon
            link={`/houseDetail?id=${id}#contactInfoCard`}
            label="View contact info"
            height="40px"
            lineHeight="27px"
            width="192px"
            icon={['far', 'angle-double-down']}
            type="primary"
            iconPosition="left"
          />
        </div>
        <div className="my-4">
          {houseInfo.images.length < 3 ? (
            <div className="d-flex justify-content-center">
              {houseInfo.images.length > 0 && (
                <div className="row">
                  {houseInfo.images.map((image, index) => (
                    <RHImage
                      key={index}
                      image={image}
                      className="img-fluid rounded-2 p-0"
                      alt="Facility image"
                      width={'480px'}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : (
            <HDCarousel houseInfo={houseInfo} />
          )}
        </div>
      </div>
      <div className="row mb-5">
        <div className="row">
          {houseInfo.description && (
            <Text large>
              <span className="fw-bold">House Description:</span>
              {' ' + houseInfo.description}
            </Text>
          )}
          {houseInfo.abstinenceDefinition && (
            <Text large className="mt-3">
              <span className="fw-bold">Our Definition of Abstinence:</span>
              {' ' + houseInfo.abstinenceDefinition}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
}
