import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../theme';
import Strings from '../../AppData/Strings/DigitalProductsStrings';
import { useMobile } from '../../utils/hooks';
import './OrderProducts.scss';
import { ErrorText } from '../../theme/wrappers/ErrorText';

export default function OrderProducts({ id, title }) {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext();
  const [selectedDigital, setDigital] = useState(false);
  const { isMobile } = useMobile();

  register(`${id}.title`, { value: title });
  register(`${id}.selected`, { value: true });

  const isQtyError = errors && errors[id];

  const handleDigitalChange = (event) => {
    const digital = event.target.checked;
    setDigital(digital);
    setValue(`${id}.digital`, digital);
    if (digital) {
      setValue(`${id}.selected`, true);
    } else {
      setValue(`${id}.selected`, false);
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === `${id}.digital`) {
        setDigital(value[id].digital);
      }
    });

    return () => subscription.unsubscribe();
  }, [getValues, id, watch]);

  return (
    <div className={classnames({ 'row mx-0': isMobile })}>
      <Text bold className="mb-2 px-0">
        {Strings.DIGITAL_PRODUCTS.ORDER_PRODUCTS.title}
      </Text>
      <div className={classnames({ 'col-6 mx-0 px-0': isMobile })}>
        <div>
          <input
            {...register(`${id}.digital`)}
            className="form-check-input me-2 mb-2"
            type="checkbox"
            checked={selectedDigital}
            onChange={handleDigitalChange}
          />
          <label>{Strings.DIGITAL_PRODUCTS.ORDER_PRODUCTS.product_types.digital}</label>
        </div>
      </div>
      <div className={classnames({ 'col-6 mx-0 px-0': isMobile })}>
        <div
          className={classnames(
            { 'mt-4 pt-2': isMobile && !isQtyError },
            { 'my-5': !isMobile && !isQtyError },
            { 'my-2': !isMobile && isQtyError },
          )}
        >
          {isQtyError && (
            <ErrorText className="px-1">
              <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
              {Strings.DIGITAL_PRODUCTS.ERRORS.add_quality}
            </ErrorText>
          )}
        </div>
        <div className={classnames('row', 'product-qty', { 'mt-0': isMobile })}></div>
      </div>
    </div>
  );
}
