import React from 'react';
import { useSpringCarousel } from 'react-spring-carousel-js';
import { CarouselItem } from './CarouselItem';
import { ArrowButton } from '../ArrowButton';
import { useMobile } from '../../../utils/hooks';
import './CardCarousel.scss';

export function CardCarousel({ carouselData }) {
  const carouselItems = carouselData.map((obj, index) => ({
    id: `CarouselItem-${index}`,
    renderItem: <CarouselItem data={obj} />,
  }));

  const { isMobile, isTablet } = useMobile();
  let noItemsPerSlide = 3;
  if (isMobile) {
    noItemsPerSlide = 1;
  } else if (isTablet) {
    noItemsPerSlide = 2;
  }

  const { carouselFragment, slideToPrevItem, slideToNextItem } = useSpringCarousel({
    withLoop: true,
    itemsPerSlide: noItemsPerSlide,
    initialStartingPosition: 'center',
    items: carouselItems,
    draggingSlideTreshold: 0,
  });

  return (
    <div className="carousel-container">
      <ArrowButton direction="prev" onClick={slideToPrevItem} />
      {carouselFragment}
      <ArrowButton direction="next" onClick={slideToNextItem} />
    </div>
  );
}
