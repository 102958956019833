import React from 'react';
import { Checkbox, Dropdown } from '../../../components';
import { Text } from '../../../theme';
import Strings from '../../../AppData/Strings/HousesStrings';
import './SearchFilters.scss';
import { SearchDistanceData } from '../../../AppData/GetHelpConstants/LocationDistance';
import AutoComplete from '../../../components/shared/AutoComplete';

export function LocationSearchFilters({ searchStateCopy, setSearchStateCopy, setInvalidSearch }) {
  return (
    <div className="accordion-item search-border">
      <div className="accordion-header" id="headingOne">
        <button
          type="button"
          className="accordion-button search-format p-0"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <Text bold large className="text-left mb-2 col">
            {Strings.Houses.LOCATION.text}
          </Text>
        </button>
      </div>
      <div
        id="collapseOne"
        className="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#searchFiltersAccordion"
      >
        <div className="row accordion-body">
          <div className="col-12 col-lg-6 pe-2">
            <div className="row row-cols-2 my-3">
              <label className="label-text">{Strings.Houses.CITY_STATE.text}</label>
              <AutoComplete
                border={true}
                componentState={searchStateCopy}
                setComponentState={setSearchStateCopy}
                setInvalidSearch={setInvalidSearch}
              />
            </div>
            <div className="row row-cols-2 my-3">
              <label className="label-text">{Strings.Houses.RADIUS.text}</label>
              <Dropdown
                instanceId="distanceSearchFilter"
                isClearable={false}
                options={SearchDistanceData}
                placeholder={Strings.Houses.SELECT.text}
                border={true}
                value={
                  SearchDistanceData.find((element) => element.id == searchStateCopy.radius) || ''
                }
                updateSelection={(value) => {
                  setSearchStateCopy({ ...searchStateCopy, radius: value ? value.id : '' });
                }}
              />
            </div>
          </div>
          <Checkbox
            className={'col-12 col-lg-6 ps-3 my-3'}
            label={Strings.Houses.ONLY_SHOW_AVAILABLE_HOUSES.text}
            isDefaultChecked={searchStateCopy.facilityAvailable || false}
            onChange={(e) =>
              setSearchStateCopy({ ...searchStateCopy, facilityAvailable: e.target.checked })
            }
          />
        </div>
      </div>
      <hr></hr>
    </div>
  );
}
