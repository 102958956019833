import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../scss/color.module.scss';
import { useLocalStorage } from '../../utils/hooks';
import classnames from 'classnames';
import './FavoriteButton.scss';

export function FavoriteButton({
  domain,
  favoriteKey,
  defaultValue,
  size = 'lg',
  backgroundColor = 'none',
  style = 'default',
  message = '',
}) {
  const [favorite, setFavorite, removeItem] = useLocalStorage(domain, favoriteKey);

  const onClick = (e) => {
    e.preventDefault();
    if (favorite) {
      removeItem();
    } else {
      setFavorite(defaultValue);
    }
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames({
        'border-0 px-0 pt-0': style === 'default',
        'favorite-button-bg-white': backgroundColor === 'none',
        'favorite-button-bg-gray': backgroundColor === 'mediumgray',
        'btn shadow add-to-favorites': style != 'default',
      })}
    >
      <FontAwesomeIcon
        icon={favorite ? ['fas', 'star'] : ['far', 'star']}
        size={size}
        color={colors.favorites}
      />
      {favorite && message != '' ? 'Remove favorite' : message}
    </button>
  );
}
