import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../theme';
import colors from '../../scss/color.module.scss';

export function CloseButton({ onClick, color }) {
  return (
    <>
      <Text very_large onClick={onClick} className="changeCursor" aria-label="Close">
        <FontAwesomeIcon
          className="m-0"
          icon={['fal', 'times']}
          color={color == 'lite' ? colors.textwhite : colors.textblack}
        />
      </Text>
    </>
  );
}
