import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { HeroImage, Text } from '../../theme';
import { LinkButtonWithIcon, Loading, MainSearch, Pagination, SubHeading } from '../../components';
import { useMobile } from '../../utils/hooks';
import { ListViewCard } from './ListViewCard';
import { MapView } from './MapView';
import { ViewOptions, BasicFilters } from './BasicFilters';
import Strings from '../../AppData/Strings/HousesStrings';
import './Houses.scss';
import { MobileBasicFilters } from './MobileBasicFilters';
import GoToTop from '../../utils/goToTop';
import createHouseDetailsModel from '../../utils/hooks/createHouseDetailsModel';
import { useSearch } from '../../utils/useSearch';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchValue } from '../../features/searchSlice';
import GuidedSearchModal from '../../components/modals/GuidedSearch/GuidedSearchModal';
import store from '../../store';
import { tabTitle } from '../../utils/tabTitle';
import { ConversionTrackingPixel } from '../../utils/embedTrackingScript';

export default function Houses() {
  const [selectedViewType, setSelectedViewType] = useState(ViewOptions[0]);
  let houseList = [];
  const { isTablet } = useMobile();
  const { setSearchDataFromUrl, updateGetHelpData } = useSearch();
  const history = useHistory();
  const getHelpData = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  tabTitle('Recovery Houses - Find Recovery Housing Now');
  const searchLocation = {
    lat: useSelector((state) => state.search.latitude),
    long: useSelector((state) => state.search.longitude),
  };

  const GETHELP_API = process.env.REACT_APP_GETHELP_API;

  useEffect(() => {
    setSearchDataFromUrl();

    let removeListener = history.listen(() => {
      // Listen for hard back button presses so we can update the search data
      if (history.action === 'POP') {
        setSearchDataFromUrl();
      }
    });

    // Remove the listener when component goes away
    return function cleanup() {
      removeListener();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHouses = async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, getHelpParams] = queryKey;
    // eslint-disable-next-line max-len
    const { data } = await axios.get(
      `${GETHELP_API}/v2/facilities?province=ky&facilityUpToDate=true&sort=distance&pageSize=${itemsPerPage}${getHelpParams}`,
    );

    return data;
  };

  const {
    isLoading: isSearchLoading,
    isSuccess: isSearchSuccess,
    error: searchError,
    data: apiSearchData,
  } = useQuery(
    [
      'search',
      getHelpData.params,
      'timestamp',
      getHelpData.timestamp, // This is never used but exists to force the query to re-run
    ],
    getHouses,
    { refetchOnWindowFocus: false },
  );

  if (isSearchSuccess) {
    for (let i = 0; i < apiSearchData.content.length; i++) {
      const house = createHouseDetailsModel(apiSearchData.content[i]);
      houseList.push(house);
    }
  }

  const onNextClick = () => {
    dispatch(setSearchValue({ field: 'page', value: apiSearchData.pageable.pageNumber + 1 }));
    updateGetHelpData(store.getState().search);
  };

  const onPreviousClick = () => {
    dispatch(setSearchValue({ field: 'page', value: apiSearchData.pageable.pageNumber - 1 }));
    updateGetHelpData(store.getState().search);
  };

  const DisplayResults = () => {
    if (searchError) {
      return <Text>An error has occurred, please try again</Text>;
    } else if (houseList.length > 0) {
      if (selectedViewType.value === '1') {
        return <MapView houses={houseList} searchLocation={searchLocation} />;
      } else {
        return (
          <>
            {houseList.map((house, index) => (
              <ListViewCard
                id={house.id}
                house={house}
                searchLocation={searchLocation}
                key={`listviewcard-${index}`}
              />
            ))}
            <div className="row ms-md-1 mb-4">
              <div className="col-sm-12 col-md-3 mb-4">
                <div className="row mx-0">
                  <Pagination
                    onPreviousClick={onPreviousClick}
                    onNextClick={onNextClick}
                    currentPage={apiSearchData.pageable.pageNumber + 1}
                    totalCounts={apiSearchData.totalElements}
                    itemsPerPage={itemsPerPage}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-9 order-md-first text-center text-md-start">
                <LinkButtonWithIcon
                  type="printBtn"
                  link=""
                  onClick={(e) => {
                    e.preventDefault();
                    window.print();
                  }}
                  label="Print list of Recovery Houses"
                  boxShadow="none"
                  iconPosition="left"
                  icon={['fa', 'print']}
                  decoration="underline"
                />
              </div>
            </div>
          </>
        );
      }
    } else {
      return (
        <Text>
          No results found. Try modifying your search radius or other filters and search again.
        </Text>
      );
    }
  };

  return (
    <div>
      {isTablet ? null : (
        <>
          <HeroImage
            extra_large
            className="d-flex flex-column justify-content-center"
            image="housesHero"
          >
            <span className="text-center">
              <Text shadow extra_large white className="my-4">
                Your below search generated {apiSearchData && apiSearchData.totalElements} results
              </Text>
              <div className="container-xxl pb-3">
                <MainSearch type="modifySearch" />
              </div>
              <SubHeading
                text={Strings.Houses.SUGGESTED_RESOURCES.text}
                path="/resources"
                linkText={Strings.Houses.VIEW_RESOURCES.text}
              />
            </span>
          </HeroImage>
        </>
      )}
      <div className="container-xxl">
        <div className="my-4">
          {isTablet ? (
            <MobileBasicFilters
              setSelectedViewType={setSelectedViewType}
              selectedViewType={selectedViewType}
            />
          ) : (
            <BasicFilters setSelectedViewType={setSelectedViewType} />
          )}
        </div>
        <div className="my-4">
          {isSearchLoading ? (
            <Loading />
          ) : (
            <>
              <DisplayResults />
            </>
          )}
        </div>
      </div>
      <ConversionTrackingPixel />
      <GoToTop />
      <GuidedSearchModal />
    </div>
  );
}
