export const RolesConstants = {
  ROLE_FRIEND_OR_FAMILY: 'ROLE_FRIEND_OR_FAMILY',
  ROLE_HEALTHCARE_PROFESSIONAL: 'ROLE_HEALTHCARE_PROFESSIONAL',
  ROLE_INDIVIDUAL: 'ROLE_INDIVIDUAL',
  ROLE_OPERATOR: 'ROLE_OPERATOR',
  ROLE_CRIMINAL_JUSTICE: 'ROLE_CRIMINAL_JUSTICE',
  ROLE_TREATMENT_PROVIDER: 'ROLE_TREATMENT_PROVIDER',
  ROLE_OTHER: 'ROLE_OTHER',
};

export const RolesData = [
  {
    id: RolesConstants.ROLE_FRIEND_OR_FAMILY,
    label: 'Friend or Family Member',
  },
  {
    id: RolesConstants.ROLE_INDIVIDUAL,
    label: 'Individual Seeking Recovery Housing',
  },
  {
    id: RolesConstants.ROLE_HEALTHCARE_PROFESSIONAL,
    label: 'Healthcare Professional',
  },
  {
    id: RolesConstants.ROLE_TREATMENT_PROVIDER,
    label: 'Substance Use Disorder Treatment Professional/Provider',
  },
  {
    id: RolesConstants.ROLE_CRIMINAL_JUSTICE,
    label: 'Criminal Justice Professional',
  },
  {
    id: RolesConstants.ROLE_OPERATOR,
    label: 'Recovery Housing Operator/Staff',
  },
  {
    id: RolesConstants.ROLE_OTHER,
    label: 'Other',
  },
];

export const rolesWithoutOther = RolesData.filter((role) => role.id !== RolesConstants.ROLE_OTHER);
