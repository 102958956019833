import React, { useEffect, useRef } from 'react';
import { Toast } from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../scss/color.module.scss';

export default function RHToast({ showToast, setShowToast }) {
  const toastRef = useRef(null);
  const toastTimeout = useRef(null);

  useEffect(() => {
    let myToast = toastRef.current;
    let bsToast = Toast.getInstance(myToast);

    const hideToast = () => {
      if (toastRef.current) {
        Toast.getInstance(toastRef.current).hide();
        setShowToast(false);
      }
    };

    const displayToast = () => {
      if (toastRef.current) {
        clearTimeout(toastTimeout.current);
        Toast.getInstance(toastRef.current).show();
        setShowToast(true);
        toastTimeout.current = setTimeout(hideToast, 1500);
      }
    };

    if (!bsToast) {
      bsToast = new Toast(myToast, { autohide: false });
      Toast.getInstance(toastRef.current).hide();
    } else {
      showToast ? displayToast() : hideToast();
    }

    return () => {
      clearTimeout(toastTimeout.current);
    };
  }, [showToast, setShowToast]);

  return (
    <div className="position-fixed bottom-0 start-50 translate-middle-x p-3">
      <div
        id="myToast"
        ref={toastRef}
        className="toast bg-primary align-items-center"
        style={{ width: '12rem' }}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-bs-delay={2000}
      >
        <div className="d-flex">
          <div className="toast-body text-white fw-bold">Copied to Clipboard</div>
          <button
            type="button"
            className="border-0 bg-transparent"
            data-bs-dismiss="toast"
            onClick={() => {
              clearTimeout(toastTimeout.current);
              setShowToast(false);
            }}
            aria-label="Close"
          >
            <FontAwesomeIcon icon={['fas', 'times']} color={colors.white} />
          </button>
        </div>
      </div>
    </div>
  );
}
