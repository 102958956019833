import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { useQuery } from 'react-query';
import { useMobile } from '../../utils/hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { Search, Dropdown, Loading, Pagination } from '../../components';
import { HeroImage, Text } from '../../theme';
import colors from '../../scss/color.module.scss';
const { secondary } = colors;
import { ErrorText } from '../../theme/wrappers/ErrorText';
import ProductCard from './ProductCard';
import Strings from '../../AppData/Strings/DigitalProductsStrings';
import './Products.scss';
import { CheckoutProducts } from './CheckoutProducts';
import GoToTop from '../../utils/goToTop';
import { useWindowHeight } from '@react-hook/window-size/throttled';
import { tabTitle } from '../../utils/tabTitle';

const itemsPerPage = 6;

const getProducts = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, { selectedSortBy, searchText, page }] = queryKey;

  let searchTextQuery = '';
  if (searchText) {
    searchTextQuery = `text=${searchText}`;
  }

  let sortByQuery = '';
  if (selectedSortBy) {
    sortByQuery = `&sort_by=${selectedSortBy.value}`;
  }

  const pageQuery = `&page=${page}`;

  const baseURL = `${process.env.REACT_APP_API_ROOT}/api/assets/products/search/`;
  const { data } = await axios.get(`${baseURL}?${searchTextQuery}${sortByQuery}${pageQuery}`);

  return data;
};

const sortByOptions = [
  { value: '0', label: 'Recommended' },
  { value: '1', label: 'Date added (newest)' },
  { value: '2', label: 'Name (a-z)' },
  { value: '3', label: 'Name (z-a)' },
  { value: '4', label: 'Content type' },
  { value: '5', label: 'Organization (a-z)' },
  { value: '6', label: 'Organization (z-a)' },
];

export default function Products() {
  const [page, setPage] = useState(1);
  const [prevPage, setPrevPage] = useState();
  const [totalCounts, setTotalCounts] = useState(0);
  const [searchText, setSearchText] = useState(null);
  const [selectedSortBy, setSelectedSortBy] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [zeroSelected, setZeroSelected] = useState(false);
  const [modal, setModal] = useState(null);
  const checkoutProductsModal = useRef();
  const { isMobile } = useMobile();
  const methods = useForm();
  const scrollToPagination = useRef();
  const isMounted = useRef(false);
  tabTitle('Products - Find Recovery Housing Now');

  const [initialHeight] = useState(window.innerHeight);
  const windowHeight = useWindowHeight({ fps: 60 }); // Only update every 60 frames

  const isMobileKeyboardOpen = isMobile && windowHeight < initialHeight;

  useEffect(() => {
    setModal(new Modal(checkoutProductsModal.current));
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (page > prevPage) {
        window.scrollTo(0, 0);
      } else {
        scrollToPagination.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const isValid = methods.formState.isValid && Object.keys(methods.formState.errors).length == 0;

  const getOrderedItems = () => {
    const allItems = methods.getValues();
    const orderedItems = [];
    for (const key in allItems) {
      let add = false;
      const item = {
        id: key,
        title: allItems[key].title,
        digital: false,
        hardCopy: false,
        quantity: 0,
      };

      if (allItems[key].digital) {
        if (Array.isArray(allItems[key].digital)) {
          if (allItems[key].digital.length > 0) {
            item.digital = true;
            add = true;
          }
        } else {
          item.digital = true;
          add = true;
        }
      }
      if (allItems[key].hardCopy) {
        if (Array.isArray(allItems[key].hardCopy)) {
          if (allItems[key].hardCopy.length > 0) {
            (item.hardCopy = true), (item.quantity = allItems[key].quantity);
            add = true;
          }
        } else {
          (item.hardCopy = true), (item.quantity = allItems[key].quantity);
          add = true;
        }
      }
      if (add) {
        orderedItems.push(item);
      }
    }

    return orderedItems;
  };

  const onSubmit = () => {
    const orderedItems = getOrderedItems();
    setSelectedItems(orderedItems);
    if (orderedItems.length > 0) {
      setZeroSelected(false);
      modal.show();
    } else {
      setZeroSelected(true);
    }
  };

  const { isSuccess, data } = useQuery(
    ['productsData', { selectedSortBy, searchText, page }],
    getProducts,
    {
      onSuccess: (data) => {
        if (data.count != totalCounts) {
          setTotalCounts(data.count);
        }
      },
    },
  );

  const onNextClick = () => {
    setPrevPage(page);
    setPage(page + 1);
  };

  const onPreviousClick = () => {
    setPrevPage(page);
    setPage(Math.max(page - 1, 1));
  };

  const onSubmitSearchText = (data) => {
    setPage(1);
    setSearchText(data.searchText);
  };

  const createErrorMessage = (defaultText, background) => {
    return (
      <>
        {isValid && zeroSelected == false
          ? defaultText
          : zeroSelected == false && (
              <ErrorText bgWhite={background == 'showBackground'} className="p-2">
                <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                {Strings.DIGITAL_PRODUCTS.ERRORS.all_fields}
              </ErrorText>
            )}
        {zeroSelected == true && (
          <ErrorText bgWhite={background == 'showBackground'} className="p-2">
            <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
            {Strings.DIGITAL_PRODUCTS.ERRORS.none_selected}
          </ErrorText>
        )}
      </>
    );
  };

  return (
    <FormProvider {...methods}>
      <header>
        <HeroImage
          height={isMobile ? '200px' : '160px'}
          className="d-flex flex-column justify-content-center"
          image="heroimage"
        >
          <span className="mt-4 text-center mb-3">
            <Text XX_large white shadow>
              Products
            </Text>
          </span>
          <div className="col d-flex justify-content-center">
            <Text large white shadow className="text-center col col-md-8 mx-2 mx-md-0 mb-5">
              {Strings.DIGITAL_PRODUCTS.BROWSE}
            </Text>
          </div>
        </HeroImage>
        <div className="container-xxl">
          <div
            className={classnames(
              'row',
              'mt-4',
              'my-1',
              { 'mx-4': !isMobile },
              { 'mx-0': isMobile },
            )}
          >
            <div className="search-bar col-9 col-md-7 col-lg-8 px-0 mb-3">
              <Search
                placeholder="Search products"
                handleSubmitSearchText={onSubmitSearchText}
                setSearchText={setSearchText}
              />
            </div>
            <div className="col col-md-5 col-lg-4 p-0">
              {isMobile ? (
                <div className="sort ms-auto p-0">
                  <Dropdown
                    instanceId="sort_by"
                    options={sortByOptions}
                    placeholder="Sort"
                    menuWidth="350%"
                    updateSelection={setSelectedSortBy}
                    controlShouldRenderValue={false}
                    dropdownColor="white"
                    withoutCarat={true}
                    border={true}
                    placeHolderStyle={{
                      color: secondary,
                    }}
                  />
                </div>
              ) : (
                <div className="d-flex flex-row mx-0">
                  <div className="d-flex flex-row justify-content-center flex-grow-1">
                    <Text className="px-0 sort-label">Sort by:</Text>
                    <div className="rec-select">
                      <Dropdown
                        defaultValue={sortByOptions[0]}
                        instanceId="sort_by"
                        options={sortByOptions}
                        updateSelection={setSelectedSortBy}
                      />
                    </div>
                  </div>
                  {isSuccess && (
                    <div className="label-padding px-0 justify-content-end flex-shrink-1 flex-nowrap">
                      <Text italic>{data.count}&nbsp; results</Text>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="container-xxl">
          <div>
            {isSuccess ? (
              data.count == 0 ? (
                'No products found'
              ) : (
                <div
                  className={classnames(
                    'row',
                    'no-underline',
                    'my-0',
                    { 'mx-4': !isMobile },
                    { 'mx-0': isMobile },
                  )}
                >
                  {data.results.map((product, index) => (
                    <ProductCard key={`product-${index}`} product={product} />
                  ))}
                </div>
              )
            ) : (
              <Loading />
            )}
          </div>
          <div
            className={classnames('row', { 'mx-0': isMobile }, { 'ms-5 mb-4': !isMobile })}
            ref={scrollToPagination}
          >
            {isSuccess && data.count > 0 && (
              <div className="col-sm-12 col-md-3 mb-4">
                <div className="row mx-0">
                  <Pagination
                    onPreviousClick={onPreviousClick}
                    onNextClick={onNextClick}
                    currentPage={page}
                    totalCounts={totalCounts}
                    itemsPerPage={itemsPerPage}
                  />
                </div>
              </div>
            )}
            <div className="col-sm-12 col-md-9">
              <Text
                className={classnames(
                  'mb-4',
                  { 'text-center': isMobile },
                  { 'text-end': !isMobile },
                )}
              >
                {isMobile ? (
                  !isMobileKeyboardOpen && (
                    <div className="row">
                      <div className="sticky-button px-4 py-3">
                        <div className="pb-2">{createErrorMessage(null)}</div>
                        <input
                          className={classnames('btn', 'btn-primary', 'complete-my-order', 'px-4', {
                            'ms-5 me-4': !isMobile,
                          })}
                          type="submit"
                          value="Complete my order"
                        />
                      </div>
                    </div>
                  )
                ) : (
                  <div>
                    {createErrorMessage(Strings.DIGITAL_PRODUCTS.ALL_SELECTIONS)}
                    <input
                      className={classnames('btn', 'btn-primary', 'complete-my-order', 'px-4', {
                        'ms-5 me-4': !isMobile,
                      })}
                      type="submit"
                      value="Complete my order"
                    />
                  </div>
                )}
              </Text>
            </div>
          </div>
        </div>
      </form>
      <CheckoutProducts
        refCheckout={checkoutProductsModal}
        modal={modal}
        orderedItems={selectedItems}
      />
      <GoToTop />
    </FormProvider>
  );
}
