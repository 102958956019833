import React from 'react';
import Resident from './Resident';
import { Text } from '../../theme';
import { ResidentsServedConstants } from '../../AppData/GetHelpConstants/ResidentsServed';

export default function ResidentsServed(props) {
  const { residentsServed, priorityPopulations } = props;

  if (
    residentsServed.includes(ResidentsServedConstants.POP_MALE_ADULT) &&
    residentsServed.includes(ResidentsServedConstants.POP_MALE_ONLY)
  ) {
    residentsServed.splice(residentsServed.indexOf(ResidentsServedConstants.POP_MALE_ONLY), 1);
  }
  if (
    residentsServed.includes(ResidentsServedConstants.POP_FEMALE_ADULT) &&
    residentsServed.includes(ResidentsServedConstants.POP_FEMALE_ONLY)
  ) {
    residentsServed.splice(residentsServed.indexOf(ResidentsServedConstants.POP_FEMALE_ONLY), 1);
  }
  if (residentsServed.includes(ResidentsServedConstants.POP_COED)) {
    residentsServed.splice(residentsServed.indexOf(ResidentsServedConstants.POP_COED), 1);
  }

  return (
    <div id="contactInfoCard">
      {residentsServed.length > 0 && (
        <div className="row mb-5 mt-3">
          <Text XX_large bold>
            Residents served
          </Text>
          {residentsServed.map((residentType, index) => (
            <Resident key={index} residentType={residentType} />
          ))}
        </div>
      )}
      {priorityPopulations.length > 0 && (
        <div className="row mb-5">
          <Text XX_large bold className="mb-4">
            Priority populations
          </Text>
          <div>
            This house accepts all populations listed above, but has programs or services that
            specifically cater to the following:
          </div>
          {priorityPopulations.map((residentType, index) => (
            <Resident key={index} residentType={residentType} />
          ))}
        </div>
      )}
    </div>
  );
}
