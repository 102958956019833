import styled from 'styled-components';

const RHImage = styled.img.attrs((props) => ({
  src: props.image,
}))`
  width: ${(props) => (props.height ? '100%' : (props) => props.width)};
  object-fit: ${(props) => props.objectFit || 'cover'};
  height: ${(props) => (props.width ? '100%' : (props) => props.height)};
  border-radius: ${(props) => props.radius || ''};
`;

export { RHImage };
