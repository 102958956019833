import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Dropdown } from '../../components';
import './MainSearch.scss';
import { Text } from '../../theme';
import { createOptions } from '../../utils/createDropdownOptions';
import { RolesData } from '../../AppData/GetHelpConstants/Roles';
import { useSearch } from '../../utils/useSearch';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchValue } from '../../features/searchSlice';
import store from '../../store';
import colors from '../../scss/color.module.scss';
const { primary, placeholderColor } = colors;
import AutoComplete from '../../components/shared/AutoComplete';
import { SearchDistanceData } from '../../AppData/GetHelpConstants/LocationDistance';
import { ErrorText } from '../../theme/wrappers/ErrorText';
import ReactGA from 'react-ga4';

export function MainSearch({ type = 'mainSearch' }) {
  const isMainSearch = type == 'mainSearch' ? true : false;
  const isModifySearch = type == 'modifySearch' ? true : false;
  const { onSubmit } = useSearch();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.search.role);
  const cityStateZip = useSelector((state) => state.search.cityStateZip);
  const latitude = useSelector((state) => state.search.latitude);
  const longitude = useSelector((state) => state.search.longitude);
  const radius = useSelector((state) => state.search.radius);
  const gender = useSelector((state) => state.search.gender);
  const initialState = { role, gender, cityStateZip, latitude, longitude, radius };
  const [searchStateCopyForSearchBar, setSearchStateCopyForSearchBar] = useState(initialState);
  const [invalidSearch, setInvalidSearch] = useState(false);

  useEffect(() => {
    setSearchStateCopyForSearchBar(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gender, cityStateZip, role, latitude, longitude, radius]);

  let filterClass = classnames(
    'form-control',
    'filter',
    { 'main-filter-size': isMainSearch },
    { 'modify-filter-size': isModifySearch },
  );

  const handleSearch = () => {
    for (const [key, value] of Object.entries(searchStateCopyForSearchBar)) {
      dispatch(setSearchValue({ field: key, value }));
    }

    if (
      searchStateCopyForSearchBar.radius &&
      searchStateCopyForSearchBar.cityStateZip &&
      !searchStateCopyForSearchBar.latitude
    ) {
      setInvalidSearch(true);
    } else {
      onSubmit(store.getState().search);
      setInvalidSearch(false);
    }

    ReactGA.event({
      category: 'search',
      action: 'searchButtonClicked',
      label: 'mainSearch',
    });
  };

  return (
    <>
      <div
        className={classnames(
          'input-group',
          'search-heading',
          { 'main-search': isMainSearch },
          { 'modify-search': isModifySearch },
        )}
      >
        <div className={filterClass}>
          <p className="filter-label">I am a...</p>
          <Dropdown
            isClearable={true}
            instanceId="audienceType"
            options={RolesData}
            placeholder="Select your role"
            centerPlaceholderText
            placeHolderStyle={{
              fontSize: isMainSearch ? '16px' : '14px',
              color: isMainSearch ? primary : placeholderColor,
              fontWeight: isMainSearch ? '600' : '',
            }}
            value={
              RolesData.find((element) => element.id === searchStateCopyForSearchBar.role) || ''
            }
            updateSelection={(value) => {
              setSearchStateCopyForSearchBar({
                ...searchStateCopyForSearchBar,
                role: value ? value.id : '',
              });
            }}
          />
        </div>
        <div className={classnames('distance', filterClass)}>
          <p className="filter-label">Searching for houses...</p>
          <Dropdown
            instanceId="distance"
            isClearable={false}
            options={SearchDistanceData}
            centerPlaceholderText
            placeHolderStyle={{
              fontSize: isMainSearch ? '16px' : '14px',
              color: isMainSearch ? primary : placeholderColor,
              fontWeight: isMainSearch ? '600' : '',
            }}
            value={
              SearchDistanceData.find(
                (element) => element.id == searchStateCopyForSearchBar.radius,
              ) || ''
            }
            updateSelection={(value) => {
              setSearchStateCopyForSearchBar({
                ...searchStateCopyForSearchBar,
                radius: value ? value.id : '',
              });
            }}
          />
        </div>
        <div className={classnames('ps-0', 'city', filterClass, { mainSearchCity: isMainSearch })}>
          <p className="filter-label">In the following location...</p>
          <AutoComplete
            componentState={searchStateCopyForSearchBar}
            setComponentState={setSearchStateCopyForSearchBar}
            setInvalidSearch={setInvalidSearch}
            placeholderStyle={{
              fontSize: isMainSearch ? '16px' : '14px',
              color: isMainSearch ? primary : placeholderColor,
              fontWeight: isMainSearch ? '600' : '',
            }}
          />
        </div>
        <div className={classnames('border-0', 'gender', 'px-0', filterClass)}>
          <p className="filter-label">Searching for housing that serves...</p>
          <Dropdown
            instanceId="gender"
            isClearable={true}
            options={createOptions('gender')}
            placeholder="Select population"
            centerPlaceholderText
            placeHolderStyle={{
              fontSize: isMainSearch ? '16px' : '14px',
              color: isMainSearch ? primary : placeholderColor,
              fontWeight: isMainSearch ? '600' : '',
            }}
            value={
              createOptions('gender').find(
                (element) => element.id === searchStateCopyForSearchBar.gender,
              ) || ''
            }
            updateSelection={(value) => {
              setSearchStateCopyForSearchBar({
                ...searchStateCopyForSearchBar,
                gender: value ? value.id : '',
              });
            }}
          />
        </div>
        <button
          className={classnames('input-group-text', 'search-button', { 'px-lg-5': isMainSearch })}
          id="basic-addon2"
          type="button"
          onClick={handleSearch}
        >
          <Text bold white>
            {isMainSearch ? 'START SEARCH' : 'MODIFY SEARCH'}
          </Text>
        </button>
      </div>
      <div className={isMainSearch ? 'mt-2' : 'mt-3'}>
        {invalidSearch && (
          <ErrorText bgWhite="showBackground" className="p-2">
            <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
            <span>You must enter a city or zip code from the autocomplete dropdown options.</span>
          </ErrorText>
        )}
      </div>
    </>
  );
}
