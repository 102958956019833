import { createSlice } from '@reduxjs/toolkit';

export const initialSearchState = {
  role: '',
  gender: '',
  services: {},
  amenities: {},
  cityStateZip: '',
  latitude: '',
  longitude: '',
  radius: '25',
  characteristics: [],
  language: '',
  transportationOptions: {
    public: '',
  },
  facilityAvailable: true,
  legalExceptions: [],
  paymentTypes: '',
  avgCostMin: '',
  avgCostMax: '',
  accreditationLevel: '',
  urineTestRequired: '',
  houseMeetings: '',
  moudCapable: '',
  accessibility: {},
  smokingAllowed: '',
  name: '',
  accreditations: { NARR: true },
  associations: {},
  medications: '',
  structureType: '',
  favoriteFacilities: '',
  page: 0,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState: initialSearchState,
  reducers: {
    setSearchValue: (state, action) => {
      state[action.payload.field] = action.payload.value;
    },
    setSearchState: (state, action) => {
      return action.payload;
    },
    resetSearchState: () => {
      return { ...initialSearchState };
    },
  },
});

export const { setSearchValue, setSearchState, resetSearchState } = searchSlice.actions;

export default searchSlice.reducer;
