import { ApplicationConstants } from '../../AppData/GetHelpConstants/Application';
import { DepositConstants } from '../../AppData/GetHelpConstants/Deposit';
import { LanguageConstants } from '../../AppData/GetHelpConstants/Languages';
import { ResidentRequirementsConstants } from '../../AppData/GetHelpConstants/ResidentRequirements';
import { ResidentsServedConstants } from '../../AppData/GetHelpConstants/ResidentsServed';
import { PaymentConstants } from '../../AppData/GetHelpConstants/Payment';
import getImageByKey from '../getImages';

export default function CreateHouseDetailsModel(GetHelpHouseDetails, type) {
  const rentListValues = {
    depositRequired: DepositConstants.DEP_DEPOSIT_REQ,
    financialAssistance: DepositConstants.DEP_FINANCIAL_ASSISTANCE,
    applicationFeeRequired: DepositConstants.DEP_APPLICATION_FEE,
    rentPaidWeekly: DepositConstants.DEP_WEEKLY_PAYMENTS,
    depositDue: DepositConstants.DEP_FLEXIBLE,
  };

  const applicationListValues = {
    applicationRequired: ApplicationConstants.APPLICATION_REQUIRED,
    screeningRequired: ApplicationConstants.SCREENING_REQUIRED,
    urineTestRequired: ApplicationConstants.URINE_TEST_REQUIRED,
    backgroundCheckRequired: ApplicationConstants.BACKGROUND_CHECK_REQUIRED,
  };

  const ResidentRequirementsValues = {
    chores: ResidentRequirementsConstants.REQ_CHORES,
    curfew: ResidentRequirementsConstants.REQ_CURFEW,
    houseMeetings: ResidentRequirementsConstants.REQ_MEETINGS,
  };

  const splitList = (value) => {
    let list = [];
    if (GetHelpHouseDetails[value] != null && GetHelpHouseDetails[value] !== '') {
      list = GetHelpHouseDetails[value].split(',');
    } else {
      list = [];
    }

    return list;
  };

  const getValue = (value) => {
    let item;
    const conditions = ['null', 'undefined', ''];
    if (GetHelpHouseDetails[value] && Array.isArray(GetHelpHouseDetails[value])) {
      if (conditions.some((element) => GetHelpHouseDetails[value].includes(element))) {
        item = [];
      } else {
        item = GetHelpHouseDetails[value];
      }
    } else if (
      GetHelpHouseDetails[value] == undefined ||
      GetHelpHouseDetails[value] === 'null' ||
      GetHelpHouseDetails[value] === 'undefined' ||
      GetHelpHouseDetails[value] === ''
    ) {
      item = null;
    } else {
      item = GetHelpHouseDetails[value];
    }

    return item;
  };

  const createList = (valueList) => {
    let list = [];
    for (var key in valueList) {
      if (getValue(`${key}`) == true) {
        list.push(`${valueList[key]}`);
      } else if (typeof GetHelpHouseDetails[key] === 'string') {
        list.push(GetHelpHouseDetails[key]);
      }
    }

    return list;
  };

  const createAmenitiesList = (value) => {
    let list = value && value.map((element) => element.code);

    return list;
  };

  const serviceList = (site) => {
    let list = [];
    let result;
    if (site) {
      result = Object.entries(site).map(([key, value]) => ({
        key,
        value,
      }));
      result.map((element) => {
        if (element.value.length === 0) {
          list.push(element.key);
        } else {
          list.push(...element.value);
        }
      });
    }

    return list;
  };

  const imagePlaceholder = (images) => {
    let imageList = [];
    if (images.length == 0) {
      imageList.push(getImageByKey('NoPhoto'));
    } else {
      imageList = images;
    }

    return imageList;
  };

  const checkConstants = (value) => {
    const getHelpList = splitList(value);
    let list = [];
    let constantsList;
    switch (value) {
      case 'residentServed':
        constantsList = ResidentsServedConstants;
        break;
      case 'priorityPopulations':
        constantsList = ResidentsServedConstants;
        break;
      case 'languages':
        constantsList = LanguageConstants;
        break;
      case 'paymentMethods':
        constantsList = PaymentConstants;
        break;
    }

    for (let key in getHelpList) {
      if (Object.values(constantsList).includes(getHelpList[key])) {
        list.push(getHelpList[key]);
      }
    }

    return list;
  };

  let HOUSE_DETAILS = {
    id: getValue('id'),
    published: getValue('published'),
    status: getValue('status'), //shows availability of beds need to check options, GetHelp has 4
    updatedAt: getValue('bedUpdatedAt'),
    name: getValue('name'),
    genderType: getValue('genderType'),
    address: {
      privateAddress: getValue('privateAddress'),
      address1: getValue('address1'),
      address2: getValue('address2'),
      city: getValue('city'),
      county: getValue('county'),
      state: getValue('state'),
      zipCode: getValue('zipCode'),
      country: getValue('country'),
      latitude: getValue('latitude'),
      longitude: getValue('longitude'),
    },
    phone: getValue('phone'),
    website: getValue('website'),
    images: imagePlaceholder(getValue('images')),
    certifications: {
      certs: getValue('accreditations'),
      compliesLicensing: getValue('compliesLicensing'),
    },
    rent: {
      rentFrom: getValue('avgCostMinimum'),
      amountDue: getValue('depositAmount'),
    },
    acceptInsurance: getValue('acceptsInsurance'),
    weeklyPaymentsAllowed: getValue('rentPaidWeekly'),
    residentsServed: checkConstants('residentServed'),
    beds: getValue('beds'),
    baths: getValue('baths'),
    rulesPolicies: {
      smokingPolicy: getValue('smokingPolicy'),
    },
  };

  const addFullHouseDetails = (HOUSE_DETAILS) => {
    Object.assign(HOUSE_DETAILS, { description: getValue('description') });
    Object.assign(HOUSE_DETAILS, { abstinenceDefinition: getValue('abstinenceDefinition') });
    Object.assign(HOUSE_DETAILS, { email: getValue('email') });
    Object.assign(HOUSE_DETAILS, { facebookUrl: getValue('facebookUrl') });
    Object.assign(HOUSE_DETAILS, { twitterUrl: getValue('twitterUrl') });
    Object.assign(HOUSE_DETAILS, { linkedinUrl: getValue('linkedinUrl') });
    Object.assign(HOUSE_DETAILS, { instagramUrl: getValue('instagramUrl') });
    Object.assign(HOUSE_DETAILS, { thumbnailURL: getValue('thumbnailUrl') });
    Object.assign(HOUSE_DETAILS, {
      population: {
        residentsServed: checkConstants('residentServed'),
        priorityPopulations: checkConstants('priorityPopulations'),
      },
    });
    Object.assign(HOUSE_DETAILS, { languages: checkConstants('languages') });
    Object.assign(HOUSE_DETAILS, {
      rent: {
        general: createList(rentListValues),
        amenities: createAmenitiesList(getValue('amenities')),
        paymentOptions: checkConstants('paymentMethods'),
        depositInfo: {
          amountDue: getValue('depositAmount'),
          dueDate: getValue('depositDue'),
        },
        additionalFeesRequired: getValue('additionalFeesRequired'),
        additionalFeeAmount: getValue('additionalFeeAmount'),
      },
    });
    Object.assign(HOUSE_DETAILS.rulesPolicies, {
      housePolicyDocuments: getValue('housePolicyDocuments'),
      medAssistTreatments: splitList('moudPolicy'),
      acceptedFormsMedAssist: splitList('moudMedications'),
    });
    Object.assign(HOUSE_DETAILS, {
      bedTypes: {
        totalBeds: getValue('beds'),
        totalBaths: getValue('baths'),
        recommendedStay: getValue('recommendedStay'),
        accommodations: getValue('accommodations'),
      },
    });
    Object.assign(HOUSE_DETAILS, {
      accessibility: splitList('accessibility'),
    });
    Object.assign(HOUSE_DETAILS, {
      residentRequirements: {
        residentRequirementsList: createList(ResidentRequirementsValues),
        minAbstinence: getValue('minAbstinence'),
        correctionsBackground: splitList('legalExceptions'),
      },
    });
    Object.assign(HOUSE_DETAILS, {
      transportation: splitList('transportationOptions'),
    });
    Object.assign(HOUSE_DETAILS, {
      servicesPrograms: {
        inHouse: serviceList(GetHelpHouseDetails.onsiteServices),
        offSite: serviceList(GetHelpHouseDetails.offsiteServices),
        additionWithinWalking: splitList('additionalWalkablePlaces'),
        otherWalkablePlaces: getValue('otherWalkablePlaces'),
      },
    });
    Object.assign(HOUSE_DETAILS, {
      applicationProcess: {
        applicationRequirements: createList(applicationListValues),
        applicationLink: getValue('applicationLink'),
        applicationDocument: getValue('applicationDocument'),
      },
    });
    Object.assign(HOUSE_DETAILS, { structureType: getValue('structureType') });
    Object.assign(HOUSE_DETAILS, { allowWaitList: getValue('allowWaitList') });
  };

  if (type === 'houseDetail') {
    addFullHouseDetails(HOUSE_DETAILS);
  }

  return HOUSE_DETAILS;
}
