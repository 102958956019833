import React from 'react';
import { useDistanceLabel } from '../../utils/hooks/useDistanceLabel';

export function DistanceLabel({ house, searchLocation }) {
  const distance = useDistanceLabel(house.address, searchLocation);

  return (
    <>
      <span className="fw-bold">{`${house.address.city}, ${house.address.state} `}</span>
      <span> {distance}</span>
    </>
  );
}
