/**
 * Utility function for replacing variables in a JSON file.
 *
 * @param {JSON | string} json
 *   JSON to replace variables in.
 *
 * @param {Array} replace
 *  Array of key/value pairs to replace in given JSON.
 *
 * @returns
 *  JSON with variables replaced.
 */
export function jsonReplace(json, replace) {
  let result = json;

  // If JSON is a string, convert to JSON.
  if (typeof result !== 'string') {
    result = JSON.stringify(result);
  }

  // Search for keys given in replace for placeholders in JSON and replace.
  for (var key in replace) {
    result = result.replace(new RegExp(key, 'g'), replace[key]);
  }

  return JSON.parse(result);
}
