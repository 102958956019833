import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMobile } from '../../utils/hooks';
import classNames from 'classnames';
import './Pagination.scss';

export function Pagination({
  onPreviousClick,
  onNextClick,
  currentPage,
  totalCounts,
  itemsPerPage,
}) {
  const { isTablet } = useMobile();

  const getStartingCount = () => {
    return currentPage > 1 ? itemsPerPage * (currentPage - 1) + 1 : 1;
  };

  const getEndingCount = () => {
    return itemsPerPage * currentPage > totalCounts ? totalCounts : itemsPerPage * currentPage;
  };

  return (
    <div className="pagination">
      <div
        className={classNames('col', 'd-flex', 'justify-content-end', {
          'justify-content-center': isTablet,
        })}
      >
        {isTablet && (
          <button
            type="button"
            onClick={onPreviousClick}
            className="border-0 pagination-button ps-2 pt-0"
            disabled={currentPage === 1}
          >
            <FontAwesomeIcon icon={['fas', 'caret-left']} />
          </button>
        )}
        <span className="ms-1 me-2">
          {getStartingCount()} - {getEndingCount()} of {totalCounts}
        </span>
        {!isTablet && (
          <button
            type="button"
            onClick={onPreviousClick}
            className="border-0 pagination-button ps-2 pt-0"
            disabled={currentPage === 1}
          >
            <FontAwesomeIcon icon={['fas', 'caret-left']} />
          </button>
        )}
        <button
          type="button"
          onClick={onNextClick}
          className="border-0 pagination-button ps-2 pt-0"
          disabled={currentPage === Math.ceil(totalCounts / itemsPerPage)}
        >
          <FontAwesomeIcon icon={['fas', 'caret-right']} />
        </button>
      </div>
    </div>
  );
}
