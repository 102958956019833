import React from 'react';
import { Text } from '../../theme';

export default function Entries({ obj: { term, definition } }) {
  return (
    <div>
      <Text extra_large bold className="mt-4">
        {term}
      </Text>
      <Text dangerouslySetInnerHTML={{ __html: definition }} />
    </div>
  );
}
