import React from 'react';
import { FooterLinks } from './FooterLinks';
import { Text } from '../../../theme';

export function FooterSections(props) {
  const data = props;

  if (data.obj.label === 'Sitemap' && data.obj.elements[1] == undefined) {
    data.NavBar.map(function (obj) {
      if (obj != undefined && obj.label != 'Glossary') {
        return data.obj.elements.push(obj);
      }
    });
  }

  return (
    <div className={`col-md-${data.obj.column} col-sm-12 offset-sm-1-block pb-3`}>
      <Text white bold className="pb-2">
        {data.obj.label.toUpperCase()}
      </Text>
      {data.obj.elements.map((obj, index) => (
        <FooterLinks key={index} obj={obj} />
      ))}
    </div>
  );
}
