import { useHistory } from 'react-router-dom';
import { initialSearchState } from '../features/searchSlice';
import { ServicesData } from '../AppData/GetHelpConstants/Services';
import { ResidentsServedData } from '../AppData/GetHelpConstants/ResidentsServed';
import { PaymentMethodsData } from '../AppData/GetHelpConstants/Payment';
import { LanguagesData } from '../AppData/GetHelpConstants/Languages';
import { CriminalBackgroundData } from '../AppData/GetHelpConstants/CriminalBackground';
import {
  MedTreatmentConstants,
  MedTreatmentData,
} from '../AppData/GetHelpConstants/MedTreatmentDetails';
import { AccessibilityData } from '../AppData/GetHelpConstants/Accessibility';
import { useDispatch } from 'react-redux';
import { setSearchState } from '../features/searchSlice';
import { setGetHelpData } from '../features/getHelpDataSlice';
import store from '../store';
import { cloneDeep } from 'lodash';
import { AccreditationsData } from '../AppData/GetHelpConstants/Accreditations';
import { TransportationConstants } from '../AppData/GetHelpConstants/Transportation';
import { KYRHData } from '../AppData/GetHelpConstants/NARRLevels';
import { stateOptions } from '../components/shared/StateDropdown';
import ReactGA from 'react-ga4';
import { AmenitiesData } from '../AppData/GetHelpConstants/Amenities';

export function useSearch() {
  const history = useHistory();
  const dispatch = useDispatch();

  const buildSearchDataFromUrl = (params) => {
    const tempSearchData = cloneDeep(initialSearchState);

    const setTempSearchData = (field, value) => {
      tempSearchData[field] = value;
    };

    if (!params || 'facilityAvailable' in params == false) {
      setTempSearchData('facilityAvailable', false);
    }

    if (!params || 'accreditations' in params == false) {
      setTempSearchData('accreditations', '');
    }

    if (params) {
      for (const [key, value] of Object.entries(params)) {
        switch (key) {
          case 'city':
          case 'zip':
            {
              if (value) {
                const city = params.city ? params.city : '';
                const zip = params.zip ? params.zip : '';
                setTempSearchData('cityStateZip', `${city} ${zip}`);
              }
            }
            break;
          case 'characteristics':
          case 'legalExceptions':
            {
              let dataValues = [];
              const parameters = value.split(',');
              let dataList;
              if (key === 'characteristics') {
                dataList = ResidentsServedData;
              } else if (key === 'legalExceptions') {
                dataList = CriminalBackgroundData;
              }
              parameters.map((item) => {
                dataValues.push(dataList.find((element) => element.id === item));
              });
              setTempSearchData(key, dataValues);
            }
            break;
          case 'transportationOptions':
            {
              let transportationList = value.split(',');
              let transportationObject = {};
              const personalTransportationOptions = [
                TransportationConstants.VEHICLE_ALLOWED,
                TransportationConstants.VEHICLE_ALLOWED_RESTRICT,
                TransportationConstants.VEHICLE_STAY,
              ];
              transportationList.map((item) => {
                if (personalTransportationOptions.includes(item)) {
                  transportationObject.public = item;
                } else {
                  transportationObject[item] = true;
                }
              });
              setTempSearchData(key, transportationObject);
            }
            break;
          case 'services':
          case 'amenities':
          case 'accessibility':
          case 'accreditations':
            {
              let list = value.split(',');
              let object = {};
              list.forEach((item) => {
                object[item] = true;
              });
              setTempSearchData(key, object);
            }
            break;
          case 'associations':
            {
              let associationsList = value.split(',');
              let associationsObject = {};
              associationsList.map((item) => {
                const decodedItem = decodeURIComponent(item);
                associationsObject[decodedItem] = true;
              });
              setTempSearchData(key, associationsObject);
            }
            break;
          case 'favoriteFacilities':
            {
              setTempSearchData(key, decodeURIComponent(value));
            }
            break;
          default: {
            if (value) {
              setTempSearchData(key, value == 'true' ? true : value);
            }
          }
        }
      }
    }

    return tempSearchData;
  };

  const parseCityState = (value) => {
    const locationObject = {
      city: '',
      zip: '',
    };
    if (!value) {
      return locationObject;
    }
    locationObject.zip = value.match(/\d+/g);
    locationObject.city = value.replace(/\d+/g, '').trim();

    return locationObject;
  };

  const removeStateFromCity = (city) => {
    let cityArray = city.split(' ');
    let cityArrayUppercase = city.toUpperCase().split(' ');
    const stateList = stateOptions[1].options;
    for (let i = 0; i < stateList.length; i++) {
      if (cityArrayUppercase.includes(stateList[i].label.toUpperCase())) {
        cityArray.splice(cityArrayUppercase.indexOf(stateList[i].label.toUpperCase()), 1);
      }
      if (cityArrayUppercase.includes(stateList[i].value)) {
        cityArray.splice(cityArrayUppercase.indexOf(stateList[i].value), 1);
      }
    }

    return cityArray.join(' ');
  };

  const buildParams = (initialData = null) => {
    let recoveryHousingParams = '';
    let getHelpParams = '';
    let getHelpUrlServicesList = [KYRHData.service_id]; // Default value to show only KYRH houses
    const searchDataObj = initialData ? initialData : store.getState().search;

    function handleServiceCase(type, data, value) {
      if (Object.keys(value).length > 0) {
        const object = value;
        let list = [];
        for (const [key, value] of Object.entries(object)) {
          if (value) {
            list.push(key);
            getHelpUrlServicesList.push(data.find((element) => element.id === key)?.service_id);
          }
        }
        if (list.length > 0) {
          recoveryHousingParams += `&${type}=${list.join()}`;
        }
      }
    }

    for (const [key, value] of Object.entries(searchDataObj)) {
      switch (key) {
        case 'cityStateZip':
          {
            const locationObject = parseCityState(value);
            if (locationObject.zip) {
              recoveryHousingParams += `&zip=${locationObject.zip}`;
              if (!searchDataObj.radius) {
                getHelpParams += `&postalCode=${locationObject.zip}`;
              }
            }
            if (locationObject.city) {
              recoveryHousingParams += `&city=${locationObject.city}`;
              if (!searchDataObj.radius) {
                getHelpParams += `&city=${removeStateFromCity(locationObject.city)}`;
              }
            }
          }
          break;
        case 'characteristics':
        case 'legalExceptions':
          {
            if (value.length > 0) {
              let dataValues = [];
              value.map((item) => {
                dataValues.push(item?.id);
                if (key === 'characteristics') {
                  getHelpUrlServicesList.push(item.service_id);
                }
              });
              if (dataValues.includes('NON_APPLICABLE')) {
                dataValues = [];
              }
              if (dataValues.length > 0) {
                recoveryHousingParams += `&${key}=${dataValues}`;
              }
              if (key === 'legalExceptions') {
                getHelpParams += `&${key}=${dataValues.join()}`;
              }
            }
          }
          break;
        case 'transportationOptions':
          {
            if (Object.keys(value).length > 0) {
              const object = value;
              let list = [];
              for (const [key, value] of Object.entries(object)) {
                if (value) {
                  if (key === 'public') {
                    list.push(value);
                  } else {
                    list.push(key);
                  }
                }
              }
              if (list.length > 0) {
                recoveryHousingParams += `&${key}=${list.join()}`;
                getHelpParams += `&${key}=${list.join()}`;
              }
            }
          }
          break;
        case 'moudCapable':
          {
            if (value === true) {
              recoveryHousingParams += `&${key}=${value}`;
              getHelpUrlServicesList.push(
                MedTreatmentData.find(
                  (element) => element.id === MedTreatmentConstants.MED_TREATMENT_MOUD,
                ).service_id,
              );
            }
          }
          break;
        case 'medications':
          {
            if (value) {
              recoveryHousingParams += `&${key}=${value}`;
              getHelpParams += `&moud=${value}`;
            }
          }
          break;
        case 'services':
          {
            handleServiceCase(key, ServicesData, value);
          }
          break;
        case 'amenities':
          {
            handleServiceCase(key, AmenitiesData, value);
          }
          break;
        case 'page':
          {
            getHelpParams += `&${key}=${value}`;
          }
          break;
        case 'accreditations':
          {
            if (Object.keys(value).length > 0) {
              const object = value;
              let getHelpList = [];
              let recoveryList = [];
              for (const [key, value] of Object.entries(object)) {
                if (value) {
                  getHelpList.push(
                    AccreditationsData.find((element) => element.id === key).accreditation,
                  );
                  recoveryList.push(key);
                }
              }

              if (getHelpList.length > 0) {
                getHelpParams += `&${key}=${getHelpList.join()}`;
                recoveryHousingParams += `&${key}=${recoveryList.join()}`;
              }
            }
          }
          break;
        case 'associations':
          {
            if (Object.keys(value).length > 0) {
              const object = value;
              let getHelpList = [];
              let recoveryList = [];
              for (const [key, value] of Object.entries(object)) {
                if (value) {
                  getHelpList.push(
                    AccreditationsData.find((element) => element.id === key).association,
                  );
                  recoveryList.push(encodeURIComponent(key));
                }
              }

              if (getHelpList.length > 0) {
                getHelpParams += `&${key}=${getHelpList.join()}`;
                recoveryHousingParams += `&${key}=${recoveryList.join()}`;
              }
            }
          }
          break;
        case 'accessibility':
          {
            handleServiceCase(key, AccessibilityData, value);
          }
          break;
        case 'latitude':
        case 'longitude':
          {
            if (value) {
              recoveryHousingParams += `&${key}=${value}`;
              if (searchDataObj.radius) {
                getHelpParams += `&${key}=${value}`;
              }
            }
          }
          break;
        default: {
          if (value) {
            if (key === 'gender' || key === 'language' || key === 'paymentTypes') {
              let dataList;
              switch (key) {
                case 'gender':
                  {
                    dataList = ResidentsServedData;
                    recoveryHousingParams += `&${key}=${encodeURIComponent(value)}`;
                  }
                  break;
                case 'language':
                  {
                    dataList = LanguagesData;
                    recoveryHousingParams += `&${key}=${encodeURIComponent(value)}`;
                  }
                  break;
                case 'paymentTypes':
                  {
                    dataList = PaymentMethodsData;
                    recoveryHousingParams += `&${key}=${encodeURIComponent(value)}`;
                  }
                  break;
              }
              getHelpUrlServicesList.push(
                dataList.find((element) => element.id === value).service_id,
              );
            } else {
              recoveryHousingParams += `&${key}=${encodeURIComponent(value)}`;
              if (key !== 'role') {
                getHelpParams += `&${key}=${encodeURIComponent(value)}`;
              }
            }
          }
        }
      }
    }
    if (getHelpUrlServicesList.length > 0) {
      getHelpParams += `&services=${getHelpUrlServicesList.join()}`;
    }

    return { recoveryHousingParams, getHelpParams };
  };

  const parseRHUrl = (url) => {
    if (url) {
      let paramsObject = {};
      url
        .substring(2)
        .split('&')
        .forEach((item) => {
          const newItem = item.split('=');
          if (newItem[0] === 'city') newItem[1] = newItem[1].split('%20').join(' ');
          paramsObject[newItem[0]] = newItem[1];
        });

      return paramsObject;
    }
  };

  const sendAnalytics = (searchParams) => {
    const paramsObject = parseRHUrl(searchParams);
    if (paramsObject) {
      Object.keys(paramsObject).forEach((field) => {
        if (
          field === 'legalExceptions' ||
          field === 'transportationOptions' ||
          field === 'services' ||
          field === 'characteristics' ||
          field === 'amenities'
        ) {
          if (paramsObject[field]) {
            // Ensure the field is not empty
            paramsObject[field] = paramsObject[field].split(',');
            paramsObject[field].forEach((item) => {
              if (item) {
                // Ensure the item is not empty
                ReactGA.event({
                  category: `filter-${field}`,
                  action: `${field}`,
                  label: item,
                });
              }
            });
          }
        }
      });
    }
    if (paramsObject.language) {
      delete Object.assign(paramsObject, { languages: paramsObject.language })['language'];
    }
    ReactGA.event('searchParameters', parseRHUrl(history.location.search));

    return paramsObject;
  };

  const updateGetHelpData = (searchData) => {
    const { getHelpParams } = buildParams(searchData);
    dispatch(
      setGetHelpData({
        params: getHelpParams,
        timestamp: Date.now(), // This is added just to force a refetch of the data
      }),
    );
  };

  const setSearchDataFromUrl = () => {
    const currentRHUrl = history.location.search;
    const paramsObject = parseRHUrl(currentRHUrl);

    const updatedSearchData = buildSearchDataFromUrl(paramsObject);
    dispatch(setSearchState(updatedSearchData));
    updateGetHelpData(updatedSearchData);
  };

  const updateRecoveryHousingUrl = (searchData) => {
    const { recoveryHousingParams } = buildParams(searchData);
    history.push(`/houses?${recoveryHousingParams}`);
  };

  const onSubmit = (searchData) => {
    if (Object.prototype.hasOwnProperty.call(searchData, 'page')) {
      searchData = { ...searchData, page: 0 };
    }
    updateRecoveryHousingUrl(searchData);
    updateGetHelpData(searchData);
    ReactGA.event(sendAnalytics(history.location.search));
  };

  return {
    onSubmit,
    setSearchDataFromUrl,
    updateRecoveryHousingUrl,
    updateGetHelpData,
    buildParams,
  };
}

export default useSearch;
