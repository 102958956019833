import React, { useState, useEffect } from 'react';
import { Text } from '../../../theme';
import { useGSModal, useMobile } from '../../../utils/hooks';
import { setGuidedSearchValue } from '../../../features/guidedSearchSlice';
import { useSelector, useDispatch } from 'react-redux';

export function GSTextFieldQuestion({ question, index, viewable }) {
  const [visibility, setVisibility] = useState(true);
  const { isMobile } = useMobile();
  const { questionLabel } = useGSModal();
  const fieldValue = useSelector((state) => state.guidedSearch.searchFields[question.name]);
  const applyingForSelf = useSelector((state) => state.guidedSearch.utilityStates.applyingForSelf);
  const dispatch = useDispatch();

  useEffect(() => {
    setVisibility(viewable);
    if (!viewable) {
      dispatch(setGuidedSearchValue({ field: question.name, value: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewable]);

  const handleChange = (e) => {
    dispatch(setGuidedSearchValue({ field: question.name, value: e.target.value }));

    return;
  };

  return (
    <>
      {visibility && (
        <div key={question.name + index}>
          <div className="row mb-5">
            <div className={isMobile ? 'col-12 pb-3' : 'col-6'}>
              <Text white large>
                {questionLabel(question, applyingForSelf)}
              </Text>
            </div>
            <div className={isMobile ? 'col-12' : 'col-3'}>
              <div className="row">
                <input
                  type="text"
                  className="textField"
                  placeholder={question.placeholder}
                  name={question.name}
                  value={fieldValue || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
