import React from 'react';
import getImageByKey from '../../../utils/getImages';
import { Link } from 'react-router-dom';
import { NavOverlay } from '../../../theme';
import { NavTab } from './NavTab';
import ReactGA from 'react-ga4';

ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

export function NavBar() {
  return (
    <div>
      <nav className="navbar navbar-expand-md navbar-dark bg-headerfooterbg shadow-sm">
        <div className="container-fluid px-0">
          <Link className="navbar-brand" to="/">
            <img
              src={getImageByKey('logoBlues')}
              alt="Find Recovery Housing Logo"
              width="226"
              className="d-inline-block align-text-top ps-4"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="offcanvas collapse offcanvas-end"
            tabIndex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <NavOverlay>
              <div className="offcanvas-header d-flex justify-content-between">
                <div></div>
                <h5 className="text-white">Menu</h5>
                <button
                  type="button"
                  className="btn-close btn-close-white text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <div className="navbar-nav ms-auto">
                  <NavTab isMobile />
                </div>
              </div>
            </NavOverlay>
          </div>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav ms-auto">
              <NavTab />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
