import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import { Button, CenteredWithMargins, HeroImage, RHLink, Text } from '../../theme';
import { CardCarousel, MainSearch } from '../../components';
import Strings from '../../AppData/Strings/MainPageStrings';
import { useGSModal, useMobile } from '../../utils/hooks';
import './MainPage.scss';
import { useSearch } from '../../utils/useSearch';
import GoToTop from '../../utils/goToTop';
import MobileSearchBottomSheet from './MobileSearchBottomSheet';
import GuidedSearchModal from '../../components/modals/GuidedSearch/GuidedSearchModal';
import { initialSearchState } from '../../features/searchSlice';
import { tabTitle } from '../../utils/tabTitle';
import ReactGA from 'react-ga4';

const getMainPageInfo = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, endpoint] = queryKey;
  const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/api/${endpoint}`);

  return data;
};

export default function MainPage() {
  const { isTablet } = useMobile();
  const { onGSModalOpen } = useGSModal();
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const { onSubmit } = useSearch();
  tabTitle('Find Recovery Housing Now');

  const {
    isLoading: isHeroLoading,
    isSuccess: isHeroSuccess,
    error: heroError,
    data: heroData,
  } = useQuery(['heroImage', 'main_page/heroes/'], getMainPageInfo);

  const {
    isLoading: isCarouselLoading,
    isSuccess: isCarouselSuccess,
    error: carouselError,
    data: carouselData,
  } = useQuery(['heroImage', 'main_page/carousel_info/'], getMainPageInfo);

  if (heroError) {
    return 'An hero info error has occurred: ' + heroError.message;
  }

  if (carouselError) {
    return 'An carousel info error has occurred: ' + carouselError.message;
  }

  return (
    <>
      {isHeroLoading ? (
        'loading...'
      ) : (
        <HeroImage
          className="d-flex flex-column justify-content-center"
          XXX_large
          // height={'382px'}
          image={isHeroSuccess && heroData[0] ? `url=${heroData[0].image}` : 'heroimage'}
        >
          <span className="text-center mt-5">
            {isHeroSuccess && heroData[0] ? (
              <Text
                shadow
                XXX_large
                white
                dangerouslySetInnerHTML={{
                  __html: heroData[0].label,
                }}
              ></Text>
            ) : (
              <>
                <Text bold shadow XXX_large white>
                  {Strings.MainPage.HERO.header}
                </Text>
                <Text shadow XXX_large white>
                  {Strings.MainPage.HERO.text}
                </Text>
              </>
            )}
            <div className="mainpage-search">
              {isTablet ? (
                <CenteredWithMargins>
                  <Button
                    primary
                    width="375px"
                    height="100px"
                    large
                    radius="10px"
                    lineHeight="90px"
                    onClick={() => setOpenBottomSheet(true)}
                  >
                    <FontAwesomeIcon icon={['fas', 'search']} size="lg" />
                    &nbsp;&nbsp;&nbsp;&nbsp;START YOUR SEARCH
                  </Button>
                </CenteredWithMargins>
              ) : (
                <MainSearch />
              )}
            </div>
          </span>
        </HeroImage>
      )}
      <div className="container-xxl">
        <div className="row justify-content-center pt-5">
          <div className="col-12 col-sm-5 col-lg-4 text-center px-3 mb-4">
            <div className="card shadow border-0 py-5 px-3 mb-4 h-100">
              <Text className="card-title mb-3" extra_large>
                {Strings.MainPage.guidedSearchCard.text}&nbsp;&nbsp;
              </Text>
              <button
                type="button"
                className="mx-auto btn btn-outline-primary px-4 py-3 rounded-1 mt-auto"
                onClick={(e) => {
                  e.preventDefault();
                  onGSModalOpen();
                  ReactGA.event({
                    category: 'guidedSearch',
                    action: 'guidedSearch',
                    label: 'mainPageGuidedSearch',
                  });
                }}
              >
                <span className="fw-bold">
                  {Strings.MainPage.guidedSearchCard.label}{' '}
                  <FontAwesomeIcon icon={['far', 'angle-double-right']} />
                </span>
              </button>
            </div>
          </div>
          <div className="col-12 col-sm-5 col-lg-4 text-center px-3 mb-4">
            <div className="card shadow border-0 py-5 px-3 h-100">
              <Text className="card-title mb-3" extra_large>
                {Strings.MainPage.viewAllCard.text}&nbsp;&nbsp;
              </Text>
              <button
                type="button"
                className="mx-auto btn btn-outline-primary px-4 py-3 rounded-1 mt-auto"
                onClick={() => onSubmit(initialSearchState)}
              >
                <span className="fw-bold">
                  {Strings.MainPage.viewAllCard.label}{' '}
                  <FontAwesomeIcon icon={['far', 'angle-double-right']} />
                </span>
              </button>
            </div>
          </div>
          <div className="col-12 col-sm-5 col-lg-4 text-center px-2 px-sm-3 mb-4">
            <div className="card shadow border-0 py-5 px-3 h-100">
              <Text className="card-title mb-3" extra_large>
                {Strings.MainPage.findHelpNowCard.text}&nbsp;&nbsp;
              </Text>
              <a
                className="mx-auto btn btn-outline-primary px-4 py-3 rounded-1 mt-auto"
                href={Strings.MainPage.findHelpNowCard.link}
                target="_blank"
                rel="noreferrer"
              >
                <span className="fw-bold">
                  {Strings.MainPage.findHelpNowCard.label}{' '}
                  <FontAwesomeIcon icon={['far', 'angle-double-right']} />
                </span>
              </a>
            </div>
          </div>
        </div>
        <Text bold XX_large className="text-center mt-5 mb-4 mx-5">
          {Strings.MainPage.carouselHeader.text}
        </Text>
        <div className="mx-lg-7">
          {isCarouselLoading
            ? 'loading...'
            : isCarouselSuccess &&
              carouselData.length > 0 && (
                <CardCarousel carouselData={carouselData} page="mainPage" />
              )}
        </div>
        <Text className="text-center m-5 mx-5">{Strings.MainPage.disclaimer.text}</Text>
        <Text className="text-center m-5 mx-5">
          {Strings.MainPage.aboutUsLink.text}&nbsp;&nbsp;
          <RHLink to={Strings.MainPage.aboutUsLink.link}>
            {Strings.MainPage.aboutUsLink.label}
          </RHLink>
        </Text>
        <MobileSearchBottomSheet open={openBottomSheet} close={setOpenBottomSheet} />
        <GuidedSearchModal />
        <GoToTop />
      </div>
    </>
  );
}
