import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import HDCarouselItem from './HDCarouselItem';
import './HDCarousel.scss';

const defaultResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default function HDCarousel({ houseInfo, responsive = defaultResponsive }) {
  return (
    <Carousel
      swipeable={true}
      draggable={false}
      showDots={false}
      responsive={responsive}
      infinite={false}
      keyBoardControl={true}
      customTransition="transform 300ms ease-in-out"
      transitionDuration={500}
      slidesToSlide={1}
      renderArrowsWhenDisabled={true}
      className="HDCarousel"
      // centerMode={true}
    >
      {houseInfo.images.map((image, index) => (
        <HDCarouselItem key={image + index} image={image} />
      ))}
    </Carousel>
  );
}
