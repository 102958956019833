import React from 'react';
import { LinkButton } from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LinkButtonWithIcon(props) {
  const {
    link,
    onClick,
    label,
    icon,
    type,
    width,
    radius,
    height,
    lineHeight,
    iconPosition,
    iconColor,
    iconSize,
    boxShadow,
    borderColor,
    target,
    decoration,
  } = props;

  return (
    <>
      <LinkButton
        to={link == '' ? '#' : link}
        type={type}
        onClick={onClick}
        width={width}
        radius={radius}
        height={height}
        lineheight={lineHeight}
        boxshadow={boxShadow}
        border-color={borderColor}
        target={target}
        decoration={decoration}
      >
        {iconPosition === 'left' && (
          <FontAwesomeIcon icon={icon} color={iconColor} size={iconSize} />
        )}
        {`${label} `}
        {iconPosition === 'right' && <FontAwesomeIcon icon={icon} color={iconColor} />}
      </LinkButton>
    </>
  );
}
