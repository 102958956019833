import React from 'react';
import { Text } from '../../theme';
import './AboutUs.scss';

export default function MidSecItem({ index, obj: { label, text } }) {
  return (
    <div className="row">
      <div className="col-2 text-center">
        <div className="numberCircle">{index + 1}</div>
      </div>
      <div className="col-10 text-start pe-5">
        <Text darkBlue bold extra_large className="pb-1">
          {label}
        </Text>
        <Text className="pb-3">{text}</Text>
      </div>
    </div>
  );
}
