import React from 'react';
import { Text } from '../../../theme';
import './CarouselItem.scss';
import { RHImage } from '../../../theme/wrappers/RHImage';

export function CarouselItem({ data }) {
  return (
    <div className="rh-carousel-item">
      <a href={data.link} target={'_blank'} rel="noreferrer">
        <RHImage
          className="carousel-image mw-100"
          image={data.image}
          width={'358px'}
          radius={'10px'}
          alt={data.title}
        />
        <div className="carousel-gradient pb-1 pb-md-4" key={data.id}>
          <div className="carousel-title pb-4">
            <Text italic large white bold className="pb-1 mt-3 pb-md-4 pb-lg-1 mt-md-1">
              {data.title}
            </Text>
          </div>
          <div className="carousel-text px-4 pb-lg-2">
            <Text white bold>
              {data.text}
            </Text>
          </div>
        </div>
      </a>
    </div>
  );
}
