export const ResidentsServedConstants = {
  POP_VETERANS: 'POP_VETERANS',
  // commented out for alpha release
  // POP_UNDER_18: 'POP_UNDER_18',
  POP_FEMALE_ONLY: 'POP_FEMALE_ONLY',
  POP_MALE_ONLY: 'POP_MALE_ONLY',
  POP_COED: 'POP_COED',
  POP_PREGNANCY: 'POP_PREGNANCY',
  POP_TRANS_MAN: 'POP_TRANS_MAN',
  POP_TRANS_WOMEN: 'POP_TRANS_WOMEN',
  POP_CHILDREN_MEN: 'POP_CHILDREN_MEN',
  POP_CHILDREN_WOMEN: 'POP_CHILDREN_WOMEN',
  POP_COUPLE: 'POP_COUPLE',
  POP_CHILDREN_COUPLE: 'POP_CHILDREN_COUPLE',
  POP_LGBTQ: 'POP_LGBTQ',
  POP_INTERSEX: 'POP_INTERSEX',
  POP_NON_BINARY: 'POP_NON_BINARY',
  POP_DEAF: 'POP_DEAF',
  POP_BLIND: 'POP_BLIND',
  POP_HOMELESS: 'POP_HOMELESS',
  POP_INDIGENOUS: 'POP_INDIGENOUS',
  POP_RENTRY: 'POP_RENTRY',
  POP_JUSTICE: 'POP_JUSTICE',
  POP_TRANS: 'POP_TRANS',
  POP_MALE_ADULT: 'POP_MALE_ADULT',
  POP_FEMALE_ADULT: 'POP_FEMALE_ADULT',
  POP_CHRONIC_CONDITION: 'POP_CHRONIC_CONDITION',
  TRANS_INCLUSIVE: ['POP_TRANS_MAN', 'POP_TRANS_WOMEN'],
  CO_OCCURRING_MENTAL_HEALTH_DISORDER: 'CO-OCCURRING_MENTAL_HEALTH_DISORDER',
};

export const ResidentsServedData = [
  {
    id: ResidentsServedConstants.POP_VETERANS,
    label: 'Veterans',
    icon: ['medal'],
    service_id: 224,
  },
  // commented out for alpha release
  // {
  //   id: ResidentsServedConstants.POP_UNDER_18,
  //   label: 'Under 18',
  //   icon: [
  //     'child',
  //   ],
  //   service_id: 268,
  // },
  {
    id: ResidentsServedConstants.POP_FEMALE_ADULT,
    label: 'Women',
    icon: ['venus'],
    service_id: 804,
  },
  {
    id: ResidentsServedConstants.POP_FEMALE_ONLY,
    label: 'Women',
    icon: ['venus'],
    service_id: 272,
  },
  {
    id: ResidentsServedConstants.POP_MALE_ADULT,
    label: 'Men',
    icon: ['mars'],
    service_id: 803,
  },
  {
    id: ResidentsServedConstants.POP_MALE_ONLY,
    label: 'Men',
    icon: ['mars'],
    service_id: 273,
  },
  {
    id: ResidentsServedConstants.POP_COED,
    label: 'Coed',
    icon: ['mars', 'venus'],
    service_id: 297,
  },
  {
    id: ResidentsServedConstants.POP_PREGNANCY,
    label: 'Pregnant & Expecting',
    icon: ['baby-carriage'],
    service_id: 486,
  },
  {
    id: ResidentsServedConstants.POP_TRANS_MAN,
    label: 'Trans men',
    icon: ['transgender-alt'],
    service_id: 692,
  },
  {
    id: ResidentsServedConstants.POP_TRANS_WOMEN,
    label: 'Trans women',
    icon: ['transgender-alt'],
    service_id: 693,
  },
  {
    id: ResidentsServedConstants.POP_CHILDREN_MEN,
    label: 'Men w/ children',
    icon: ['male', 'baby'],
    service_id: 713,
  },
  {
    id: ResidentsServedConstants.POP_CHILDREN_WOMEN,
    label: 'Women w/ children',
    icon: ['female', 'baby'],
    service_id: 714,
  },
  {
    id: ResidentsServedConstants.POP_COUPLE,
    label: 'Couples',
    icon: ['user-friends'],
    service_id: 715,
  },
  {
    id: ResidentsServedConstants.POP_CHILDREN_COUPLE,
    label: 'Couples w/ children',
    icon: ['male', 'female', 'baby'],
    service_id: 716,
  },
  {
    id: ResidentsServedConstants.POP_LGBTQ,
    label: 'LGBTQIA+',
    icon: ['rainbow'],
    service_id: 717,
  },
  {
    id: ResidentsServedConstants.POP_INTERSEX,
    label: 'Intersex',
    icon: ['rainbow'],
    service_id: 718,
  },
  {
    id: ResidentsServedConstants.POP_NON_BINARY,
    label: 'Non-binary',
    icon: ['rainbow'],
    service_id: 719,
  },
  {
    id: ResidentsServedConstants.POP_DEAF,
    label: 'Deaf & Hard of Hearing',
    icon: ['deaf'],
    service_id: 720,
  },
  {
    id: ResidentsServedConstants.POP_BLIND,
    label: 'Blind & Visually Impaired',
    icon: ['low-vision'],
    service_id: 721,
  },
  {
    id: ResidentsServedConstants.POP_HOMELESS,
    label: 'Homeless & Housing Insecure',
    icon: ['bed'],
    service_id: 722,
  },
  {
    id: ResidentsServedConstants.POP_INDIGENOUS,
    label: 'Indigenous',
    icon: ['map-marker-alt'],
    service_id: 723,
  },
  {
    id: ResidentsServedConstants.POP_RENTRY,
    label: 'Re-entry',
    icon: ['gavel'],
    service_id: 724,
  },
  {
    id: ResidentsServedConstants.POP_JUSTICE,
    label: 'Any Court Involvement',
    icon: ['gavel'],
    service_id: 769,
  },
  {
    id: ResidentsServedConstants.POP_CHRONIC_CONDITION,
    label: 'Chronic Medical Condition Requiring Medication',
    icon: ['briefcase-medical'],
    service_id: 827,
  },
  {
    id: ResidentsServedConstants.POP_TRANS,
    label: 'Transgender',
    icon: ['transgender-alt'],
  },
  {
    id: ResidentsServedConstants.CO_OCCURRING_MENTAL_HEALTH_DISORDER,
    label: 'Co-occurring Mental Health Disorder',
    icon: ['head-side-medical'],
    service_id: 819,
  },
];
