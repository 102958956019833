export const NARRLevelsConstants = {
  // UNDEFINED: 'UNDEFINED',
  LEVEL_1: 'LEVEL1',
  LEVEL_2: 'LEVEL2',
  LEVEL_3: 'LEVEL3',
  // LEVEL_4: 'LEVEL4',
  // LEVEL_5: 'LEVEL5',
};

export const NARRLevelsData = [
  // {
  //   id: NARRLevelsConstants.UNDEFINED,
  //   label: '',
  // },
  {
    id: NARRLevelsConstants.LEVEL_1,
    label: 'Level 1',
  },
  {
    id: NARRLevelsConstants.LEVEL_2,
    label: 'Level 2',
  },
  {
    id: NARRLevelsConstants.LEVEL_3,
    label: 'Level 3',
  },
  // commented out for alpha release
  // {
  //   id: NARRLevelsConstants.LEVEL_4,
  //   label: 'Level 4',
  // },
  // {
  //   id: NARRLevelsConstants.LEVEL_5,
  //   label: 'Level 5',
  // },
];

export const KYRHConstants = {
  KYRH: 'KYRH',
};

export const KYRHData = {
  id: KYRHConstants.KYRH,
  label: 'KYRH',
  service_id: 816,
};
