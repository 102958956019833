import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMobile } from '../../utils/hooks';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { RHMarker } from './RHMarker';
import { Loading } from '../../components';

const desktopContainerStyle = {
  width: '100%',
  height: '100vh',
};

const mobileContainerStyle = {
  width: '100%',
  height: '50vh',
};

export function MapViewCard({ houses, onHouseSelected, selectedHouseId }) {
  const { isMobile } = useMobile();
  const [center, setCenter] = useState({
    lat: 38.047989,
    lng: -84.50164,
  });

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const selectedHouse = houses.find((house) => house.id === selectedHouseId);

  useEffect(() => {
    if (selectedHouseId) {
      setCenter({ lat: selectedHouse.address.latitude, lng: selectedHouse.address.longitude });
    }
  }, [selectedHouse, selectedHouseId]);

  const mapRef = useRef();

  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;
      const fitBounds = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        houses.map((house) => {
          if (house.address.latitude && house.address.longitude) {
            bounds.extend({
              lat: house.address.latitude,
              lng: house.address.longitude,
            });
          }

          return house.id;
        });
        map.fitBounds(bounds);
      };
      fitBounds(map);
    },
    [houses],
  );

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={isMobile ? mobileContainerStyle : desktopContainerStyle}
        center={center}
        zoom={12}
        onLoad={onLoad}
      >
        {houses.map((house, index) => (
          <RHMarker
            key={`marker-${index}`}
            house={house}
            setSelectedHouse={onHouseSelected}
            houseId={house.id}
            isSelectedHouse={house.id === selectedHouseId}
          />
        ))}
      </GoogleMap>
    );
  };

  if (loadError) return 'Error loading...';
  if (!isLoaded) return 'Loading Maps...';

  return isLoaded ? renderMap() : <Loading />;
}
