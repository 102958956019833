import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../scss/color.module.scss';

// eslint-disable-next-line react/display-name
export const SaveSearchButton = forwardRef((props, ref) => (
  <button
    type="button"
    style={{ height: '40px', width: '40px' }}
    className="bg-secondary border-0 p-0"
    title="Saved Searches"
    ref={ref}
    onClick={props.onClick}
    {...props}
  >
    <FontAwesomeIcon
      icon={['fas', 'plus-circle']}
      size="lg"
      color={colors.white}
      className="m-1 p-1"
    />
  </button>
));
