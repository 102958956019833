import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

export default function BottomSheetDrawer({ open, close, children }) {
  function onDismiss() {
    close(false);
  }

  return (
    <>
      <BottomSheet open={open} blocking={false} onDismiss={onDismiss}>
        {children}
      </BottomSheet>
    </>
  );
}
