import React from 'react';
import { TrueFalseText } from '../../components/shared/TrueFalseText';
import './CheckedList.scss';

export default function CheckedList({
  returnedValues,
  comparisonList,
  type,
  size,
  transportation,
}) {
  let listType = 'pb-4';
  switch (type) {
    case 'single':
      listType += ' col-12';
      break;
    case 'inline':
      listType = ' inlineText pe-5';
      break;
    case 'modal':
      listType = 'col-4 mb-2';
      break;
    default:
      listType += ' col-6';
  }

  return (
    <>
      {comparisonList.map((comparisonListItem, index) => {
        let status = true;
        if (!returnedValues || !returnedValues.includes(comparisonListItem.id)) {
          status = false;
        }

        return (
          <div className={`${listType}`} key={index}>
            <TrueFalseText
              status={status}
              text={comparisonListItem.label}
              type={type}
              icon={comparisonListItem.icon}
              size={size}
              transportation={transportation}
            />
          </div>
        );
      })}
    </>
  );
}
